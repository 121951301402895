<template>
  <div class="loginContainer">
    <span style="font-size: 26px;color:#333333;" class="boldFont mt16">账号密码登录</span>

    <input placeholder="请输入手机号" v-model="account" class="baseFont vpd12 inputForm" style="margin-top: 50px;"/>
    <div class="divider"/>
    <input placeholder="请输入密码" v-model="password" class="baseFont vpd12 inputForm mt16" type="password"/>
    <div class="divider mb16"/>

    <nut-button class="loginBtn mt16 baseFont" @click="passwordLogin">登录</nut-button>
  </div>
  <div class="bothCenter" style="position: fixed;bottom: 20px;width: 100%">
    <span class="smallFont">跨层级数据汇聚系统</span>
  </div>
</template>


<script>
import {Dialog, Toast} from '@nutui/nutui';
import api from "@/api";
import {mapMutations} from "vuex";
import md5 from 'js-md5'

export default {
  name: "LoginView",
  data() {
    return {
      account: '',
      password: '',
      captcha: '',
      fastLoginType: false,
      isWxEnv: false,
      wxBindToken: null,
      auto: this.$route.query.auto ? this.$route.query.auto : 0,
    }
  },

  methods: {
    ...mapMutations(['updateUser', 'updateEnv']),
    wxLogin() {
      Toast.loading('自动登录中...', {
        duration: 5000
      });
      let url = this.$route.query.redirect;
      let code = this.$route.query.code;
      if (code) {
        api.wechatCodeLogin(code).then(ret => {
          Toast.hide();
          if (ret.action === 0) {
            this.accountLoginType = true;
            this.wxBindToken = ret.token;
            Toast.fail("您未绑定账号，请使用账号密码登录");
            this.refreshCaptcha();
          } else {
            this.setAccessToken(ret.accessToken);
            this.loadUserInfo();
          }
        });
      } else {
        api.getWechatRedirectUrl(url).then(ret => {
          window.location.href = ret;
        })
      }
    },
    passwordLogin() {
      if (!this.account || !this.password) {
        Toast.warn('账号、密码都是必填项');
        return
      }
      Toast.loading('登录中...');
      api.login({
        phone: this.account,
        password: md5(this.password),
        token: this.captchaToken,
        channel: 1
      }).then(ret => {
        Toast.hide();
        this.setAccessToken(ret.accessToken);
        if (this.wxBindToken) {
          Dialog({
            title: '是否绑定该账号',
            content: '绑定后再次打开可免密登录',
            onCancel: () => {
              this.loadUserInfo();
            },
            onOk: () => {
              api.bindWechat(this.wxBindToken).then(() => {
                Toast.success("绑定成功!");
              });
              this.loadUserInfo();
            }
          });
        } else {
          this.loadUserInfo();
        }
      })
    },
    setAccessToken(accessToken) {
      localStorage.setItem('access_token', accessToken);
    },
    loadUserInfo() {
      api.getUserInfo().then(userInfo => {
        document.title = '跨层级数据汇聚系统';
        this.updateUser(userInfo);
        this.updateEnv(this.isWxEnv ? 'wechat' : 'h5');

        // 取到原页面路径
        let url = this.$route.query.redirect;
        // 跳转回原页面
        this.$router.push({
          path: decodeURIComponent(url ? url : '/')
        });
      })
    },
    refreshCaptcha() {
      api.getLoginCaptchaToken().then(ret => {
        this.captchaToken = ret;
      });
    }
  },
  mounted() {
    document.title = '跨层级数据汇聚系统登录';
    //检测快捷登录码
    // if (this.$route.query.accessToken) {
    //   Toast.loading('请稍等...', {
    //     duration: 500
    //   });
    //   this.fastLoginType = true;
    //   localStorage.setItem('access_token', this.$route.query.accessToken);
    //   this.$router.push("/");
    //   return;
    // }
    //环境检测
    var userAgent = window.navigator.userAgent.toLowerCase();
    console.log(userAgent);
    if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
      this.isWxEnv = true;
    }
    if (this.isWxEnv && this.auto == 0) {
      this.wxLogin();
    }
    // } else {
    //非自动化环境
    // this.accountLoginType = true;
    // }
    // this.refreshCaptcha();
  }
}
</script>

<style lang="scss" scoped>
.loginContainer {
  display: flex;
  flex-direction: column;
  background-image: url("https://cdn.waityou.online/77cd198b-2b90-a288-67ab-9ac6dc586b79.png");
  background-size: 100%;
  min-height: 100vh;
  padding: 28px;

  .loginBtn {
    border-radius: 50px;
    background-color: rgba(27, 119, 251, 1);
    color: rgba(255, 255, 255, 1);
    height: 44px;
    border: none;
  }

  .inputForm {
    background: transparent;
    border: none;
  }

  .inputForm:focus {
    outline: none;
  }
}
</style>