<template>
  <div class="NameUpdateViewContainer">
    <nut-row className="leftCenter wbg pd16 vmg8">
      <span class="baseFont">{{ user.nickName }}</span>
    </nut-row>
    <nut-row className="leftCenter wbg pd16 vmg8">
      <input placeholder="请输入名字" v-model="newName" style="width: 100%;padding: 5px;border: none;">
    </nut-row>
    <nut-row className="bothCenter wbg pd16 vmg8" @click="save">
      <span style="color:#1677FE;">确认修改</span>
    </nut-row>
  </div>
</template>

<script>
import api from "@/api";
import {Toast} from "@nutui/nutui";

export default {
  name: "NameUpdateView",
  data() {
    return {
      user: {},
      newName: null,
    }
  },
  methods: {
    init() {
      api.getUserInfo()
          .then(user => {
            this.user = user;
          })
    },
    save() {
      if (!this.newName) {
        Toast.fail("请输入昵称")
        return
      }
      this.user.nickName = this.newName;
      api.updateInfo(this.user)
          .then(() => {
            this.$router.back()
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.NameUpdateViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
  min-height: 100vh;

  input:focus {
    outline: none;
  }
}
</style>