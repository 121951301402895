<template>
  <div class="ParticipantListViewContainer" @click="showBottom=true">
    <nut-row className="leftCenter wbg opd16 pd12">
      <nut-col :span="22" class="leftCenter">
        <img src="https://cdn.waityou.online/ac25ca99-797d-fd83-eb4a-c5bd640b7f92.png"
             style="width: 20px;height: 20px"/>
        <nut-ellipsis :rows="1"
                      v-if="content"
                      :content="content" style="width: 90%;margin-left: 8px;"/>
        <span v-else class="baseFont ml8" style="color: #D0D0D0">添加参与人</span>
      </nut-col>
      <nut-col :span="2" class="rightCenter">
        <img src="https://cdn.waityou.online/e88c06b8-6446-b890-f314-5f8f4767a2ab.png"
             style="width: 20px;height: 20px"/>
      </nut-col>
    </nut-row>
  </div>
  <nut-popup position="bottom" :style="{ height: '80%' }"
             round
             v-model:visible="showBottom">
    <OrgUserSelector
        :userIds="userIds"
        @selectedUserId="onSelectedUserId"/>
  </nut-popup>
</template>

<script>
import OrgUserSelector from "@/components/user/OrgUserSelector";

export default {
  name: "ParticipantBarView",
  components: {OrgUserSelector},
  props: {
    userIds:{
      type:Array,
      default:()=>{
        return []
      }
    },
    content: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      showBottom: false
    }
  },
  methods: {
    onSelectedUserId(ids) {
      this.$emit('selectedUserId', ids)
      this.showBottom = false;
    },
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ParticipantListViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>