<template>
  <div class="MessageViewContainer">
    <nut-row className="bothCenter pd16" style="background: #F4F9FC">
      <nut-row class="wbg brd32 vpd8 opd12">
        <nut-col :span="20" class="leftCenter">
          <img src="https://cdn.waityou.online/7e3af399-d54d-f467-8585-0c2796b87146.png"
               style="width: 22px;height: 22px"/>
          <span class="smallFont ml16" style="color:#999999;">选择日期</span>
        </nut-col>
        <nut-col :span="4" class="rightCenter">
          <span class="smallFont ml16" style="color:#999999;">&nbsp;</span>
          <img src="https://cdn.waityou.online/11c08a33-7f62-4b42-1838-9bf1ac7b450e.png"
               style="width: 12px;height: 12px;"/>
        </nut-col>
      </nut-row>
    </nut-row>
    <InfiniteLoadingList :query-id="2">
      <template v-slot="data">
        <MessageItem :data="data.data"/>
      </template>
    </InfiniteLoadingList>
  </div>
  <TabBar :tab="3"/>
</template>

<script>
import MessageItem from "@/components/MessageItem"
import InfiniteLoadingList from "@/components/InfiniteLoadingList"
import TabBar from "@/components/TabBar";

export default {
  name: "MessageView",
  components: {MessageItem, InfiniteLoadingList, TabBar}

}
</script>

<style lang="scss" scoped>
.MessageViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>