<template>
  <div :class="expand?'CatalogInfoCardContainer active':'CatalogInfoCardContainer unactive'">
    <div class="wbg pd12">
      <nut-row class="bothCenter" @click="$emit('select')">
        <nut-col :span="22" class="colFlex wd100">
          <nut-row class="leftCenter ">
            <nut-col :span="8" class="leftCenter baseFont">
              <nut-ellipsis :rows="1"
                            :content="data.name"/>
            </nut-col>
            <nut-col :span="16" class="leftCenter smallFont">
              <nut-ellipsis :rows="1"
                            :content="data.property"
                            style="color: #999999;"
              />
            </nut-col>
          </nut-row>
          <span class="smallFont mt8" :style="{color: expand?'#1E427A':'#999999'}">数据目录总数：{{
              data.catalogSize
            }}  子目录：{{ data.subCatalogSize }}</span>
        </nut-col>
        <nut-col :span="2" class="rightCenter">
          <img src="https://cdn.waityou.online/8aef19fa-83d8-de1e-1e67-613981b1c368.png"
               v-if="expand"
               style="width: 16px;height: 16px"/>
          <img src="https://cdn.waityou.online/708fa8ac-045d-82bc-9da1-027851ca2f27.png"
               v-else
               style="width: 16px;height: 16px"/>
        </nut-col>
      </nut-row>
      <template v-if="expand">
        <template v-for="(cl,cli) in catalogData" :key="cli">
          <div class="divider mt8" style="background: #1E427A"></div>
          <nut-row class="colFlex mt8">
            <span class="smallFont boldFont">·{{ cl.propertyName }}</span>
            <!--   该分类下的目录列表           -->
            <template v-for="f in cl.list" :key="f.id">
              <nut-row class="bothCenter miniFont mt4" @click="gotoCatalog(f.id)">
                <nut-col :span="20" class="colFlex">
                  <span style="color: #999999">·{{ f.name }}</span>
                </nut-col>
                <nut-col :span="4" class="rightCenter" @click="chooseVersion(f.id)">
                  <img src="https://cdn.waityou.online/708fa8ac-045d-82bc-9da1-027851ca2f27.png"
                       v-if="chooseMode && currentCatalogId != f.id"
                       style="width: 12px;height: 12px"/>
                  <img src="https://cdn.waityou.online/8aef19fa-83d8-de1e-1e67-613981b1c368.png"
                       v-if="chooseMode && currentCatalogId == f.id"
                       style="width: 12px;height: 12px"/>
                </nut-col>
              </nut-row>
              <!--   该目录下的可编辑版本列表           -->
              <template v-for="vs in versions" :key="vs.id">
                <nut-row class="bothCenter" v-if="vs.catalogId==f.id">
                  <nut-col :span="20" class="leftCenter">
                    <nut-ellipsis :content="'👉🏻 '+vs.fileName"
                                  :rows="1" class="smallFont"/>
                    <!--                    <span class="smallFont">->{{ vs.fileName }}</span>-->
                  </nut-col>
                  <nut-col :span="4" class="rightCenter">
                    <span style="color: #1E427A"
                          @click="confirmVersion(vs.id)"
                          class="smallFont">选择</span>
                  </nut-col>
                </nut-row>
              </template>
            </template>
          </nut-row>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import {Toast} from "@nutui/nutui";

export default {
  name: "CatalogInfoCard",
  props: {
    chooseMode: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      catalogData: [],
      versions: [],
      currentCatalogId: 0,
    }
  },
  watch: {
    expand(nv) {
      if (nv) {
        api.getDataCatalogGroupByOrg({
          orgId: this.data.orgId,
          mine: this.chooseMode,
        }).then(ret => {
          this.catalogData = ret;
        })
      }
    }
  },
  methods: {
    gotoCatalog(catalogId) {
      this.$router.push('/catalog/detail/' + catalogId)
    },
    confirmVersion(versionId) {
      this.$emit('confirmVersion', versionId)
    },
    chooseVersion(catalogId) {
      if (this.versions && this.versions.length > 0 && this.versions[0].catalogId == catalogId) {
        this.versions = [];
        this.currentCatalogId = 0;
        return
      }
      api.getVersionsById({
        'catalogId': catalogId,
        lock: false,
      }).then(ret => {
        if (!ret || ret.length < 1) {
          Toast.fail('该目录下无可编辑版本')
          return
        }
        this.versions = ret;
        this.currentCatalogId = catalogId;
      })
    },
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.active {
  color: #1E427A;
  border: #1E427A 1px solid;
  border-radius: 4px;
}

.unactive {
  border: transparent 1px solid;
  border-radius: 4px;
}

.CatalogInfoCardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #F4F9FC;
  padding: 4px 0;
}
</style>