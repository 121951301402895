<template>
  <div class="CatalogSelectorContainer">
    <nut-row className="leftCenter pd12">
      <img src="https://cdn.waityou.online/988f45d4-7970-4831-3099-e6dd04a1a2d0.png"
           style="width: 40px;height: 40px"/>
      <span class="biggerFont boldFont ml16">杭州市消防救援支队</span>
    </nut-row>
    <div class="divider"></div>
    <nut-row className="bothCenter pd12">
      <nut-col :span="20" class="bothCenter">
        <nut-input left-icon="search" placeholder="输入部门、目录名字"
                   v-model="searchName"
                   @change="onSearchChange"
                   style="border: none;background: #F2F2F2;padding: 2px 4px;border-radius: 4px"/>
      </nut-col>
      <nut-col :span="4" class="bothCenter">
        <img src="https://cdn.waityou.online/afe1dbe9-be74-3f74-9ea4-af08565cbebc.png"
             @click="reset"
             style="width: 20px;height: 20px;"/>
      </nut-col>
    </nut-row>
    <div class="opd12">
      <CatalogSearchResult v-for="(sl,sli) in searchList" :key="sli" :data="sl"/>
      <CatalogInfoCard v-for="org in dataCatalogOrgList"
                       :expand="org.orgId == expandId"
                       :choose-mode="true"
                       @select="onClickCatalogInfoCard(org)"
                       @confirmVersion="onConfirmVersion"
                       :key="org.orgId" :data="org"/>
    </div>
  </div>

</template>

<script>
import api from "@/api";
import CatalogSearchResult from "@/components/catalog/CatalogSearchResult";
import CatalogInfoCard from "@/components/catalog/CatalogInfoCard";

export default {
  name: "CatalogSelector",
  components: {CatalogSearchResult, CatalogInfoCard},
  data() {
    return {
      searchName: null,
      dataCatalogOrgList: [],
      searchList: [],
      expandId:0,
    }
  },
  methods: {
    onConfirmVersion(versionId){
      this.$emit('confirmVersion',versionId)
    },
    onClickCatalogInfoCard(catalog){
      if(this.expandId > 0){
        this.expandId = 0;
        return
      }
      this.expandId = catalog.orgId;
    },
    onSearchChange() {

    },
    init() {
      api.getDataCatalogOrgList()
          .then(ret => {
            this.dataCatalogOrgList = ret;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.CatalogSelectorContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>