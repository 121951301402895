import {createStore} from 'vuex'

export default createStore({
    state: {
        user: {},
        env: 'h5',
        cachePages: []
    },
    getters: {},
    mutations: {
        updateUser(state, val) {
            state.user = val;
        },
        updateEnv(state, e) {
            state.env = e;
        },
        setCachePage(state, page) {
            var index = state.cachePages.indexOf(page);
            if (index < 0) {
                state.cachePages.push(page);
            }
        },
        delCachePage(state, page) {
            var index = state.cachePages.indexOf(page);
            if (index > -1) {
                state.cachePages.splice(index, 1);
            }
        },
    },
    actions: {},
    modules: {}
})
