<template>
  <div class="IndexTaskItemContainer smallFont">
    <nut-row class="leftCenter">
      <span style="color: #1E427A">{{ data?.startTime?.substring(5, 10) }}</span>
    </nut-row>
    <nut-row class="bothCenter">
      <nut-col :span="20" class="leftCenter">
        <span class="boldFont mt8">{{ data?.taskName }}</span>
      </nut-col>
      <nut-col :span="4" class="rightCenter">
        <span class="miniFont brd4"
              v-if="['LAUNCH','WAIT_EDIT'].includes(data.state)"
              style="color:white;background: #B6B5B5;padding: 2px 6px">未开始</span>
        <span class="miniFont brd4"
              v-if="['EDITING','REVIEW'].includes(data.state)"
              style="color:white;background: #27BF94;padding: 2px 6px">进行中</span>
        <span class="miniFont brd4"
              v-if="['STORED','SHARE'].includes(data.state)"
              style="color:white;background: #1E427A;padding: 2px 6px">已完成</span>
      </nut-col>
    </nut-row>
    <div class="divider mt8"></div>
    <nut-row class="leftCenter mt8 miniFont">
      <img src="https://cdn.waityou.online/c09aa89f-7619-39a2-b5a3-65e20c2ff114.png" style="width: 18px"/>
      <span class="ml8">{{data.startTime.substring(5,16)}} - {{data.endTime.substring(5,16)}}</span>
    </nut-row>
    <nut-row class="leftCenter mt16 miniFont">
      <img src="https://cdn.waityou.online/2ca2dcf9-45fc-6195-112d-94476bf6ecab.png" style="width: 18px"/>
      <span class="ml8">{{data.participantCnt}}</span>
    </nut-row>
    <nut-row class="leftCenter mt16 miniFont">
      <nut-col :span="12" class="leftCenter">
        <img src="https://cdn.waityou.online/7ae6c018-c35b-0cc9-d1db-3cddc1ed5a74.png" style="width: 18px"/>
        <span class="ml8">{{taskTypes[data.taskType]}}</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <span style="color: #999999">{{ data.createdTime.substring(5,16) }}</span>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "IndexTaskItem",
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      taskTypes: {
        'TheDay':'当天',
        'DATA_REPORT':'数据上报',
        'NORMALIZATION':'常态化',
        'SPECIAL':'专项',
        'OTHER':'其他',
      }
    }
  },
  methods: {
    init() {
      console.log('data:', this.data)
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.IndexTaskItemContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px 0;
}
</style>