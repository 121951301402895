<template>
  <div class="RepeatBarViewContainer leftCenter">
    <nut-row class="bothCenter">
      <nut-col :span="20" class="leftCenter">
        <img src="https://cdn.waityou.online/c5c17608-91ca-eb3e-2da2-40b54097a97a.png"
             style="width: 20px;height: 20px">
        <nut-ellipsis class="baseFont ml8" :row="1" :content="data"/>
      </nut-col>
      <nut-col :span="4" class="rightCenter">
        <img src="https://cdn.waityou.online/60100385-1d65-0245-6e43-79ae6d5a72a6.svg"
             style="width: 16px;height: 16px"/>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "DescriptionBarView",
  props: {
    data: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      options: {
        'NO_REPEAT':'不重复',
        'WORK_DAY':'每个工作日',
        'EVERY_WEEK':'每周',
        'EVERY_MONTH':'每月',
      }
    }
  },
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.RepeatBarViewContainer {
  display: flex;
  background-color: white;
  padding: 16px;
}
</style>