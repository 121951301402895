<template>
  <div class="OrgUserSelectorContainer">
    <nut-row class="leftCenter pd12">
      <img src="https://cdn.waityou.online/988f45d4-7970-4831-3099-e6dd04a1a2d0.png"
           style="width: 40px;height: 40px"/>
      <span class="biggerFont boldFont ml16">杭州市消防救援支队</span>
    </nut-row>
    <div class="divider"></div>
    <nut-row class="bothCenter pd12">
      <nut-col :span="20" class="bothCenter">
        <nut-input left-icon="search" placeholder="搜索名字"
                   v-model="searchName"
                   @change="onSearchChange"
                   style="border: none;background: #F2F2F2;padding: 2px 4px;border-radius: 4px"/>
      </nut-col>
      <nut-col :span="4" class="leftCenter">
        <img src="https://cdn.waityou.online/afe1dbe9-be74-3f74-9ea4-af08565cbebc.png"
             @click="reset"
             style="width: 20px;height: 20px;margin-left: 12px"/>
      </nut-col>
    </nut-row>
    <nut-row class="leftCenter opd12 mb8">
      <template v-for="(s,si) in stack" :key="s.id">
        <span v-if="si > 0" style="color: #999999">&nbsp; &gt;&nbsp; </span>
        <span class="smallFont" v-if="si != stack.length-1"
              @click="gotoStack(si)"
              style="color: #1E427A">{{ s.label }}</span>
        <span class="smallFont" v-else style="color: #999999">{{ s.label }} </span>
      </template>
    </nut-row>
    <div style="height: 10px;background: #F2F2F2"></div>
    <template v-if="stack.length>0">
      <nut-row class="leftCenter opd12 vpd8">
        <nut-checkbox v-model="selected[root.id]"
                      @click="onSelectChange(root.id)"></nut-checkbox>
        <span class="smallFont">全选</span>
      </nut-row>
      <div style="height: 10px;background: #F2F2F2"></div>
      <div style="display: flex;height:45vh;flex-direction:column;overflow-y: scroll;width: 100%">
        <template v-for="sub in root.children" :key="sub.id">
          <nut-row className="bothCenter opd12" v-if="sub.id < 0">
            <nut-col :span="2" class="leftCenter">
              <nut-checkbox v-model="selected[sub.id]" @click="onSelectChange(sub.id)"></nut-checkbox>
            </nut-col>
            <nut-col :span="22" class="leftCenter vpd8" style="border-bottom: #F4F4F4 1px solid">
              <img :src="sub.data"
                   style="width: 35px;height: 35px;border-radius: 5px"/>
              <span class="smallFont ml16">{{ sub.label }}</span>
            </nut-col>
          </nut-row>
          <nut-row className="bothCenter opd12" v-else>
            <nut-col :span="2" class="leftCenter">
              <nut-checkbox v-model="selected[sub.id]" @click="onSelectChange(sub.id)"></nut-checkbox>
            </nut-col>
            <nut-col :span="22" class="leftCenter vpd8"
                     @click="intoOrg(sub)"
                     style="border-bottom: #F4F4F4 1px solid">
              <span class="smallFont ml16">{{ sub.label }}（{{ sub.children.length }}）</span>
            </nut-col>
          </nut-row>
        </template>
      </div>
    </template>
  </div>
  <nut-row className="colFlex wbg" style="position: fixed;bottom: 0;width: 100%">
    <div class="divider"></div>
    <div class="bothCenter opd12 vpd8">
      <nut-col :span="12" class="leftCenter">
        <span class="miniFont">已选择（{{ selectedCnt }}）</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <nut-button style="background: #1E427A;color: white;border: none;width: 70px"
                    size="small brd4"
                    @click="confirmSelect"
                    shape="rect">确定
        </nut-button>
      </nut-col>
    </div>
  </nut-row>
</template>

<script>
import api from "@/api";
import {Toast} from "@nutui/nutui";

export default {
  name: "OrgUserSelector",
  props: {
    userIds: {
      type: Array,
      default: ()=>{
        return []
      }
    },
  },
  data() {
    return {
      selectedCnt: 0,
      searchName: null,
      stack: [],
      root: [],
      original: [],
      selected: {},
    }
  },
  methods: {
    confirmSelect() {
      let userIds = [];
      for (let k of Object.keys(this.selected)) {
        if (this.selected[k]) {
          userIds.push(k)
        }
      }
      this.$emit('selectedUserId', userIds)
    },
    onSearchChange(content) {
      if (!content || content.length < 1) {
        return
      }
      this.stack = []
      let found = this.filterByName(this.original, content)
      if (found) {
        this.root = found
        this.stack.push(found)
      }
    },
    filterByName(tree, name) {
      if (tree.label.includes(name)) {
        return tree
      }
      if (!tree.children) {
        return null;
      }
      let cd = []
      for (let sub of tree.children) {
        let subQuery = this.filterByName(sub, name)
        if (subQuery != null) {
          cd.push(subQuery)
        }
      }
      return cd.length > 0 ? {
        id: tree.id,
        label: tree.label,
        children: cd,
        leaf: tree.leaf,
      } : null;
    },
    reset() {
      this.searchName = null;
      this.stack = [this.original]
      this.root = this.original
      for (let k of Object.keys(this.selected)) {
        this.selected[k] = false;
      }
      this.selectedCnt = 0;
    },
    onSelectChange(optionId) {
      // console.log('onSelectChange', optionId, this.selected[optionId])
      if (optionId > 0) {
        this.searchAndUpdateSelectAll(this.root, optionId, this.selected[optionId]);
      }
      this.reCount()
    },
    searchAndUpdateSelectAll(tree, optionId, val) {
      // console.log('searchAndUpdateSelectAll', optionId, val)
      if (tree.id == optionId) {
        this.updateSelectAll(tree, val)
        return
      }
      if (!tree.children) {
        return;
      }
      for (let e of tree.children) {
        this.searchAndUpdateSelectAll(e, optionId, val)
      }
    },
    updateSelectAll(tree, val) {
      // console.log('updateSelectAll', tree, val)
      this.selected[tree.id] = val;
      if (tree.id < 0) {
        this.selected[tree.id] = val;
      }
      if (!tree.children) {
        return;
      }
      for (let e of tree.children) {
        this.updateSelectAll(e, val)
      }
    },
    reCount() {
      let cnt = 0;
      for (let k of Object.keys(this.selected)) {
        if (k < 0 && this.selected[k]) {
          cnt++;
        }
      }
      this.selectedCnt = cnt
    },
    intoOrg(sub) {
      if (!sub.children || sub.children.length < 1) {
        Toast.warn('此组织下无人员')
        return
      }
      this.stack.push(sub);
      this.root = sub;
    },
    gotoStack(pos) {
      this.root = this.stack[pos];
      this.stack.splice(pos + 1);
    },
    init() {
      if(this.userIds){
        console.log('userId',this.userIds)
        for(let u of this.userIds){
          this.selected[u] = true;
        }
        this.reCount();
      }
      api.getUserTree()
          .then(ret => {
            this.stack = [ret];
            this.root = ret;
            this.original = ret;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.OrgUserSelectorContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>