<template>
  <div class="IndexMeetingItemContainer">
    <span class="smallFont" style="color: #1E427A">{{ data.startTime.substring(5,10) }}</span>
    <nut-row class="bothCenter">
      <nut-col :span="20" class="leftCenter">
        <nut-ellipsis :row="1"
                      class="smallFont boldFont mt8"
                      :content="data.meetingName"/>
      </nut-col>
      <nut-col :span="4" class="rightCenter">
        <span class="miniFont brd4"
              v-if="data.state == 'LAUNCH' || data.state == 'WAIT'"
              style="color:white;background: #B6B5B5;padding: 2px 6px">未开始</span>
        <span class="miniFont brd4"
              v-if="data.state == 'MEETING'"
              style="color:white;background: #27BF94;padding: 2px 6px">进行中</span>
        <span class="miniFont brd4"
              v-if="data.state == 'COMPLETE'"
              style="color:white;background: #1E427A;padding: 2px 6px">已完成</span>
        <span class="miniFont brd4"
              v-if="data.state == 'CANCELED'"
              style="color:white;background: #B6B5B5;padding: 2px 6px">已取消</span>
      </nut-col>
    </nut-row>
    <div class="divider mt4"></div>
    <nut-row class="leftCenter mt8">
      <img src="https://cdn.waityou.online/6992f281-cb8a-0999-7cd3-93590ccc757d.png"
           style="width: 18px;height: 18px"/>
      <span class="miniFont ml8">{{data.startTime.substring(5,16)}} ~ {{data.endTime.substring(10,16)}}</span>
    </nut-row>
    <nut-row class="leftCenter mt8">
      <img src="https://cdn.waityou.online/bf0b4afb-0375-f21a-84a3-d1957aaf4c61.png"
           style="width: 18px;height: 18px"/>
      <span class="miniFont ml8">{{data.address}}</span>
    </nut-row>
    <nut-row class="leftCenter mt8">
      <img src="https://cdn.waityou.online/8779d212-29d5-93e9-c48f-53dba0c31ff5.png"
           style="width: 18px;height: 18px"/>
      <span class="miniFont ml8">{{data.participantCnt}}人</span>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "IndexMeetingItem",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.IndexMeetingItemContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px 0;
}
</style>