<template>
  <div class="AttachmentBarViewContainer">
    <nut-row class="bothCenter">
      <nut-col :span="20" class="leftCenter">
        <img src="https://cdn.waityou.online/ad4b04d2-0eb9-6eec-c791-e31355213712.png"
             style="width: 20px;height: 20px"/>
        <span class="ml8">附件</span>
      </nut-col>
      <nut-col :span="4" class="rightCenter">
        <CustomOssUploader :accept="'*/*'"
                           v-if="!disabled"
                           :key="uploaderVersion"
                           @onUploadedFiles="onUploadedFiles">
        <img src="https://cdn.waityou.online/ecd6d317-ab59-5c83-7627-a2a60b098d98.png"
             style="width: 20px;height: 20px"/>
        </CustomOssUploader>
      </nut-col>
    </nut-row>
    <nut-row class="bothCenter mt8" v-for="(d,di) in data" :key="di">
      <nut-col :span="20" class="leftCenter">
        <img src="https://cdn.waityou.online/01626408-bd58-1fb3-1fc5-1f35d2f0f2ec.png"
             v-if="isPdf(d.url)" style="height: 16px"/>
        <img src="https://cdn.waityou.online/0102fdae-a48f-ab66-7011-9d6a9c00f73d.png"
             v-else-if="isExcel(d.url)" style="height: 16px"/>
        <img src="https://cdn.waityou.online/e516ab8c-4937-3925-95d5-bb9d1d436093.png"
             v-else-if="isWord(d.url)" style="height: 16px"/>
        <img src="https://cdn.waityou.online/613f10ca-db71-9344-0256-375f7c21ff4a.png"
             v-else-if="isPPT(d.url)" style="height: 16px"/>
        <img src="https://cdn.waityou.online/149b2eb0-b176-9064-2f5e-37c44f586c30.png"
             v-else style="height: 16px"/>
        <span class="smallFont ml8">{{ d.name }}</span>
      </nut-col>
      <nut-col :span="4" class="rightCenter" @click="$emit('remove',di)">
        <img src="https://cdn.waityou.online/60100385-1d65-0245-6e43-79ae6d5a72a6.svg"
             v-if="!disabled"
             style="width: 16px;height: 16px"/>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
import CustomOssUploader from "@/components/CustomOssUploader"
export default {
  name: "AttachmentBarView",
  components:{CustomOssUploader},
  props: {
    disabled:{
      type:Boolean,
      default:false,
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      uploaderVersion: 0
    }
  },
  methods: {
    onUploadedFiles(files, fileWithNames) {
      this.uploaderVersion++;
      this.$emit('uploadedFiles', fileWithNames)
    },
    isPdf(url) {
      return url ? url.endsWith('pdf') : false;
    },
    isExcel(url) {
      return url ? url.endsWith('xls') || url.endsWith('xlsx') : false;
    },
    isWord(url) {
      return url ? url.endsWith('doc') || url.endsWith('docx') : false;
    },
    isPPT(url) {
      return url ? url.endsWith('ppt') || url.endsWith('pptx') : false;
    },
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.AttachmentBarViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
}
</style>