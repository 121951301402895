<template>
  <div class="IndexMeetingListContainer">
    <nut-row class="bothCenter smallFont">
      <nut-col :span="12" class="leftCenter">
        <div class="leftCenter"
             @click="$router.push('/')"
             style="color: #1E427A;border: #1E427A 1px solid;padding: 3px 2px 3px 10px;
border-radius: 20px 0 0 20px">
          <img src="https://cdn.waityou.online/c6a51b2b-345a-99ac-c511-342fdbe3a297.png"
               style="width: 16px;height: 16px"/>
          <span>&nbsp;</span>
        </div>
        <div class="leftCenter"
             style="color: #1E427A;
             border-top: #1E427A 1px solid;
             border-bottom: #1E427A 1px solid;
             border-right: #1E427A 1px solid;
             padding: 3px 5px;
             border-radius: 0 20px 20px 0">
          <img src="https://cdn.waityou.online/03c36d8e-7dec-68df-df2a-bed8a970e198.png"
               style="width: 16px;height: 16px"/>
          <span>&nbsp;任务&nbsp;</span>
        </div>
      </nut-col>
      <nut-col :span="12" class="rightCenter" style="color: #666666" @click="meetingTypeSelectorVisible=true">
        <span class="mr16">{{meetingTypeName}}</span>
        <nut-icon name="rect-down" size="12"></nut-icon>
      </nut-col>
    </nut-row>
    <InfiniteLoadingList :query-id="3" :init-query="{'type':meetingType}">
      <template v-slot="data">
        <IndexMeetingItem :data="data.data"
                          @click="$router.push('/meeting/detail/'+data.data.id)"/>
      </template>
    </InfiniteLoadingList>
  </div>

  <nut-picker
      v-model:visible="meetingTypeSelectorVisible"
      :columns="meetingTypes"
      title="类型"
      @confirm="confirmMeetingType"
  >
  </nut-picker>
</template>

<script>
import InfiniteLoadingList from "@/components/InfiniteLoadingList";
import IndexMeetingItem from "@/components/meeting/IndexMeetingItem";

export default {
  name: "IndexMeetingList",
  components:{IndexMeetingItem, InfiniteLoadingList},
  data() {
    return {
      meetingTypeSelectorVisible: false,
      meetingType: null,
      meetingTypeName: '全部',
      meetingTypes: [
        {text: '全部', value: -1},
        {text: '会议', value: 0},
        {text: '活动', value: 1},
      ],
    }
  },
  methods: {
    confirmMeetingType(op) {
      this.meetingType = op.selectedValue[0];
      this.meetingTypeName = op.selectedOptions[0].text;
    },
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.IndexMeetingListContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  padding-bottom: 80px;
}
</style>