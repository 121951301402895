<template>
  <div class="AboutUsContainer colFlex">
    <div class="logo bothCenter">
      <img src="https://cdn.waityou.online/36a65eb4-4bcc-8584-a339-8013a3f9a709.jpeg" style="border-radius: 8px">
    </div>
    <nut-row class="bothCenter colFlex vmg16">
      <span class="biggerFont">小河“治”街</span>
      <span class="baseFont">Version 1.0</span>
    </nut-row>

    <div class="fontText bothCenter">
      <span>浙江泰和智谷科技有限公司技术支持</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style lang="scss" scoped>

.AboutUsContainer {
  background-color: white;
  height: 100vh;

  .fontText{
    width: 100%;
    font-size: 14px;
    position: absolute;
    bottom: 30px;
    color: #666666;
  }
  .title{
    font-size: $baseFontSize;
    padding: 10px 0;
  }
  .subTitle{
    font-size: $smallerFontSize;
    color: #BAC4D0;
  }

  .logo {
    padding-top: 30%;

    img {
      width: 80px;
      height: 80px;
    }
  }
}
</style>