<template>
  <div class="indexContainer">
    <IndexWeekDay/>
    <IndexTaskList/>
  </div>
  <TabBar :tab="0"/>
</template>

<script>
import TabBar from "@/components/TabBar";
import api from "@/api";
import IndexWeekDay from "@/components/index/IndexWeekDay";
import IndexTaskList from "@/components/index/IndexTaskList";

export default {
  name: "TaskIndexView",
  components: {
    IndexTaskList,
    IndexWeekDay,
    TabBar
  },
  data() {
    return {
      onlyMeFilter: false,
      moreTab: false,
      temperature: '-',
    }
  },
  watch: {
    $route() {
      window.location.reload();
    },
  },
  methods: {
    renderPage() {
      document.title = '跨层级数据汇聚系统';
      api.getBanners().then(ret => {
        this.banners = ret;
      });
    }
  },
  mounted() {
    this.renderPage();
  }
}
</script>
<style scoped lang="scss">
.indexContainer {
  display: flex;
  min-height: calc(100vh - 20px);
  flex-direction: column;

  .menuItem {
    img {
      width: 36px;
    }
  }

  .indexHead {
    margin-bottom: 10px;
  }

  .logoTitle {
    color: #1388ff;
    font-weight: bold;
    font-style: italic;
  }

  .temperature {
    font-weight: lighter;
  }
}

.emClassItem {
  .nut-grid-item__content {
    padding: 5px 10px;
  }
}


.emClassRoom {
  margin-top: 10px;
  display: flex;
  font-size: $baseFontSize;

  .classRoomTitle {
    width: 100%;
    padding: 10px;
    background-color: white;
  }
}


.techState {
  display: flex;
  font-size: $baseFontSize;
  align-items: center;
  justify-content: center;

  img {
    width: 90%;
    padding: 5%;
    border-radius: 25px;
  }

  span {
    padding: 10px;
  }

  .techState-left {
    display: flex;
  }

  .techState-right {
    display: flex;
    font-size: $smallerFontSize;
    color: rgba(128, 128, 128, 0.47);

    span {
      padding: 0;
    }

    justify-content: flex-end;
    align-items: center;
  }
}

.indexGrid {
  border-radius: 10px;

  .indexGridBox {
  }
}

.indexGridItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.indexGridBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;

  img {
    width: 30px;
    height: 30px;
  }

  span {
    margin-top: 5px;
    font-size: $miniFontSize;
  }
}

.linkClass {
  text-decoration: none;
}

.nut-swiper-item {
  line-height: 112px;

  img {
    width: 100%;
    height: 100%;
  }
}

.headGridItem {
  font-size: 12px;
  text-align: center;
  color: gray;
}

.bothCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>