<template>
  <div class="SettingViewContainer">
    <div class="wbg colFlex vmg8">
      <nut-row className="bothCenter wbg pd16">
        <nut-col :span="16" class="leftCenter">
          <span class="baseFont">头像</span>
        </nut-col>
        <nut-col :span="8" class="rightCenter">
          <CustomOssUploader @onUploadedFiles="updateAvatar" :key="avatarVersion">
            <img class="avatar" :src="user.avatar"/>
          </CustomOssUploader>
          <nut-icon name="right" size="12"></nut-icon>
        </nut-col>
      </nut-row>
      <div class="divider"></div>
      <nut-row className="bothCenter wbg pd16">
        <nut-col :span="16" class="leftCenter">
          <span class="baseFont">名字</span>
        </nut-col>
        <nut-col :span="8" class="rightCenter" @click="$router.push('/setting/name')">
          <span class="baseFont">{{ user.nickName }}</span>
          <nut-icon name="right" size="12"></nut-icon>
        </nut-col>
      </nut-row>
    </div>
    <div class="wbg colFlex vmg8" @click="$router.push('/setting/pass')">
      <nut-row className="bothCenter wbg pd16">
        <nut-col :span="16" class="leftCenter">
          <span class="baseFont">修改密码</span>
        </nut-col>
        <nut-col :span="8" class="rightCenter">
          <nut-icon name="right" size="12"></nut-icon>
        </nut-col>
      </nut-row>
    </div>
    <div class="wbg colFlex vmg8">
      <nut-row className="bothCenter wbg pd16" @click="logout">
        <span class="baseFont red">退出登录</span>
      </nut-row>
    </div>
  </div>
</template>

<script>
import CustomOssUploader from "@/components/CustomOssUploader";
import api from "@/api";

export default {
  name: "SettingView",
  components: {CustomOssUploader},
  data() {
    return {
      user: {},
      avatarVersion: 0,
    }
  },
  methods: {
    logout() {
      api.unbindWechat()
          .then(() => {
            localStorage.removeItem('access_token');
            this.$router.replace('/login?auto=1')
          })
    },
    updateAvatar(files) {
      api.updateInfo({
        avatar: files[0],
        nickName: this.user.nickName
      }).then(() => {
        this.avatarVersion++;
        this.init()
      })
    },
    init() {
      api.getUserInfo()
          .then(user => {
            this.user = user;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.SettingViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
  min-height: 100vh;

  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    margin-right: 8px;
  }
}
</style>