<template>
  <div class="IndexWeekDayContainer">
    <div class="wbg bothCenter brd4">
      <div v-for="(d,di) in days" :key="d" class="weekday colFlex bothCenter smallFont">
        <div :class="d == today?'colFlex bothCenter today':'colFlex bothCenter'">
          <span :style="{color:d==today?'white':'#989AAC'}">{{weeks[di]}}</span>
          <span>{{d}}</span>
          <span>{{d == today?'•':'&nbsp;'}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexWeekDay",
  data() {
    return {
      weeks: ['一', '二', '三', '四', '五', '六', '七'],
      days: [],
      today: new Date().getDate(),
    }
  },
  methods: {
    getThisWeekDates() {
      const now = new Date();
      const dayOfWeek = now.getDay();
      const startOfWeek = new Date(now);
      startOfWeek.setDate(now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

      const weekDates = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(startOfWeek);
        date.setDate(startOfWeek.getDate() + i);
        const day = date.getDate().toString();
        weekDates.push( day);
      }
      return weekDates;
    },
    init() {
      this.days = this.getThisWeekDates();
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.IndexWeekDayContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F9FD;
  padding: 16px 24px;

  .weekday {
    width: 14%;
  }

  .today{
    background: #1E427A;
    color:white;
    padding: 0 8px;
    border-radius: 8px;
  }
}
</style>