<template>
  <nut-row className="bothCenter wbg opd16 pd12">
    <nut-col :span="10" class="colFlex" @click="startTimeSelect=true">
      <span class="smallFont">{{ startTime?.substring(6, 10) }} {{
          allDay ? '' : getChineseDayOfWeek(timeStart)
        }}</span>
      <span class="smallFont mt8">{{ allDay ? getChineseDayOfWeek(timeStart) : startTime?.substring(11, 16) }}</span>
    </nut-col>
    <nut-col :span="4" class="bothCenter">
      <img src="https://cdn.waityou.online/b2f99152-885e-ae3b-202c-f1f214ab34d6.png"
           style="width: 24px;height: 24px"/>
    </nut-col>
    <nut-col :span="10" class="colFlex" @click="endTimeSelect=true">
      <span class="smallFont">{{ endTime?.substring(6, 10) }}  {{ allDay ? '' : getChineseDayOfWeek(timeEnd) }}</span>
      <span class="smallFont mt8">{{ allDay ? getChineseDayOfWeek(timeEnd) : endTime?.substring(11, 16) }}</span>
    </nut-col>
  </nut-row>

  <nut-datepicker
      title="开始时间选择"
      :min-date="new Date()"
      type="datetime"
      v-model:visible="startTimeSelect"
      @confirm="confirmStartTime"
  ></nut-datepicker>
  <nut-datepicker
      title="结束时间选择"
      :min-date="new Date()"
      type="datetime"
      v-model:visible="endTimeSelect"
      @confirm="confirmEndTime"
  ></nut-datepicker>
</template>

<script>
export default {
  name: "CreateStartAndEndTime",
  // eslint-disable-next-line vue/no-unused-components
  props: {
    allDay: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return null
      }
    },
  },
  watch: {
    data() {
      this.init()
    }
  },
  data() {
    return {
      startTimeSelect: false,
      endTimeSelect: false,
      startTime: this.getCurrentDateTime(),
      endTime: this.getTomorrowDate() + ' 00:00:00',
      timeStart: 0,
      timeEnd: 0,
    }
  },
  methods: {
    confirmStartTime(e) {
      let t = `${e.selectedValue[0]}-${e.selectedValue[1]}-${e.selectedValue[2]} ${e.selectedValue[3]}:${e.selectedValue[4]}:00`;
      this.startTime = t;
      this.timeStart = this.convertToTimestamp(t);
      this.$emit('timeStart', this.timeStart);
    },
    confirmEndTime(e) {
      let t = `${e.selectedValue[0]}-${e.selectedValue[1]}-${e.selectedValue[2]} ${e.selectedValue[3]}:${e.selectedValue[4]}:00`;
      this.endTime = t;
      this.timeEnd = this.convertToTimestamp(t);
      this.$emit('timeEnd', this.timeEnd);
    },
    convertToTimestamp(dateTimeString) {
      const [datePart, timePart] = dateTimeString.split(' ');
      const [year, month, day] = datePart.split('-');
      const [hours, minutes, seconds] = timePart.split(':');
      const dateObject = new Date(year, month - 1, day, hours, minutes, seconds);
      const timestamp = dateObject.getTime();
      return Math.floor(timestamp);
    },
    getCurrentDateTime(currentDate) {
      if (!currentDate) {
        currentDate = new Date();
      }
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getChineseDayOfWeek(timestamp) {
      // 将毫秒级时间戳转换为Date对象
      const date = new Date(timestamp);
      // 获取Date对象对应的星期几（0表示星期日，1表示星期一，以此类推）
      const dayOfWeek = date.getDay();
      // 将0-6的星期几转换为对应的中文表示
      const chineseDays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      // 返回对应的中文星期几
      return chineseDays[dayOfWeek];
    },
    getCurrentDate() {
      const currentDate = new Date();

      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    getTomorrowDate() {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);

      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    getTimestampForStartOfDay(date) {
      const startOfDay = new Date(date);
      startOfDay.setHours(0, 0, 0, 0);
      return startOfDay.getTime();
    },
    init() {
      if (this.data) {
        this.timeStart = this.data.timeStart;
        this.timeEnd = this.data.timeEnd;
        this.startTime = this.data.startTime;
        this.endTime = this.data.endTime;
      } else {
        const currentDate = new Date();
        this.timeStart = this.getTimestampForStartOfDay(currentDate);
        this.$emit('timeStart', this.timeStart);
        const tomorrowDate = new Date();
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        this.timeEnd = this.getTimestampForStartOfDay(tomorrowDate);
        this.$emit('timeEnd', this.timeEnd);
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.CreateStartAndEndTimeContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
}
</style>