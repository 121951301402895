<template>
  <div class="CatalogSearchResultContainer">
    <span>{{ data.label }}</span>
    <div class="wbg mt4" style="padding: 10px" v-for="(op,opi) in data.options" :key="opi">
      <nut-row class="bothCenter">
        <nut-col :span="22" class="leftCenter smallFont">
          <span v-for="(t,ti) in op.highLightTexts"
                :style="{color:t.highLight?'red':'black'}"
                :key="ti">{{ t.text }}</span>
        </nut-col>
        <nut-col :span="2" class="rightCenter">
          <img src="https://cdn.waityou.online/708fa8ac-045d-82bc-9da1-027851ca2f27.png"
               style="width: 16px;height: 16px"/>
        </nut-col>
      </nut-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "CatalogSearchResult",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.CatalogSearchResultContainer {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding: 4px 0;
}
</style>