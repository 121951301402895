<template>
  <div class="myHeader">
    <nut-row>
      <nut-col :span="5" class="leftCenter">
        <img class="avatar" :src="avatar"/>
      </nut-col>
      <nut-col :span="15" style="padding: 10px 0 0 6px;">
        <nut-row class="leftCenter">
          <span class="nickName">{{ userName }}</span>
          <span class="miniFont ml8" style="color:#1E427A;">{{ orgName }}</span>
        </nut-row>
        <nut-row>
          <span class="orgName">杭州市消防救援支队</span>
        </nut-row>
      </nut-col>
      <nut-col :span="4" style="display:flex;padding-top: 20px;justify-content: flex-end">
        <router-link to="/setting" style="text-decoration: none">
          <nut-row class="balance-container">
            <svg-icon icon-class="right" class-name="mini3"/>
          </nut-row>
        </router-link>
      </nut-col>
    </nut-row>
    <nut-row class="bothCenter mt32" style="color: #1E427A">
      <nut-col :span="20" class="leftCenter">
        <span class="boldFont">本周事项 {{week.length>0?week[0]:'-'}} 至 {{week.length>0?week[6]:'-'}}</span>
      </nut-col>
      <nut-col :span="4" class="rightCenter">
        <svg-icon icon-class="right" class-name="mini3"/>
      </nut-col>
    </nut-row>
    <nut-row class="leftCenter smallFont mt32" style="color: #1E427A">
      <img src="https://cdn.waityou.online/f37620ee-7a7b-9e24-e267-762a7df3d29a.png" style="width: 24px;height: 24px"/>
      <span class="ml16">我的任务</span>
    </nut-row>
    <nut-row class="leftCenter mt16">
      <nut-col :span="8" class="leftCenter">
        <div style="background: #BEBEBE;color: white;width:80px;height:26px;border-radius: 4px"
             class="smallFont bothCenter"><span>未开始&nbsp;0</span></div>
      </nut-col>
      <nut-col :span="8" class="leftCenter">
        <div style="background: #28BF94;color: white;width:80px;height:26px;border-radius: 4px"
             class="smallFont bothCenter"><span>进行中&nbsp;0</span></div>
      </nut-col>
      <nut-col :span="8" class="leftCenter">
        <div style="background: #FF5E46;color: white;width:80px;height:26px;border-radius: 4px"
             class="smallFont bothCenter"><span>即将开始&nbsp;0</span></div>
      </nut-col>
    </nut-row>
    <nut-row class="leftCenter mt16">
      <nut-col :span="8" class="leftCenter">
        <div style="background: #FF9800;color: white;width:80px;height:26px;border-radius: 4px"
             class="smallFont bothCenter"><span>已逾期&nbsp;0</span></div>
      </nut-col>
      <nut-col :span="8" class="leftCenter">
        <div style="background: #203971;color: white;width:80px;height:26px;border-radius: 4px"
             class="smallFont bothCenter"><span>已完成&nbsp;0</span></div>
      </nut-col>
    </nut-row>
    <nut-row class="leftCenter smallFont mt32" style="color: #1E427A">
      <img src="https://cdn.waityou.online/e64a14a5-7423-8cf2-07bd-c1c5552ac32c.png" style="width: 24px;height: 24px"/>
      <span class="ml16">我的会议</span>
    </nut-row>
    <nut-row class="leftCenter mt16">
      <nut-col :span="8" class="leftCenter">
        <div style="background: #BEBEBE;color: white;width:80px;height:26px;border-radius: 4px"
             class="smallFont bothCenter"><span>未开始&nbsp;0</span></div>
      </nut-col>
      <nut-col :span="8" class="leftCenter">
        <div style="background: #28BF94;color: white;width:80px;height:26px;border-radius: 4px"
             class="smallFont bothCenter"><span>进行中&nbsp;0</span></div>
      </nut-col>
      <nut-col :span="8" class="leftCenter">
        <div style="background: #FF5E46;color: white;width:80px;height:26px;border-radius: 4px"
             class="smallFont bothCenter"><span>即将开始&nbsp;0</span></div>
      </nut-col>
    </nut-row>
    <nut-row class="leftCenter mt16">
      <nut-col :span="8" class="leftCenter">
        <div style="background: #203971;color: white;width:80px;height:26px;border-radius: 4px"
             class="smallFont bothCenter"><span>已结束&nbsp;0</span></div>
      </nut-col>
    </nut-row>
  </div>
  <TabBar :tab="4"/>
</template>

<script>
import TabBar from "@/components/TabBar";
import SvgIcon from "@/components/SvgIcon";
import api from "@/api"
import {Toast} from '@nutui/nutui';

export default {
  name: "MyView",
  components: {SvgIcon, TabBar},
  data() {
    return {
      startTimeSelect:false,
      avatar: 'https://cdn.waityou.online/1c89cdb8-080d-d70b-7760-7bc9f5d47547.jpeg',
      regionName: '...',
      orgName: '...',
      userName: '...',
      realName: '...',
      phone: '...',
      level: '',
      nextLevel: '',
      percent: 0,
      todayIncrease: 0,
      roleId: 0,
      balance: 0,
      unread: 0,
      oldMode: false,
      week:[],
    }
  },
  methods: {
    getWeekDates(){
      const now = new Date();
      const dayOfWeek = now.getDay();
      const startOfWeek = new Date(now);
      startOfWeek.setDate(now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

      const weekDates = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(startOfWeek);
        date.setDate(startOfWeek.getDate() + i);
        const month = date.toLocaleString('zh-CN', { month: '2-digit' });
        const day = date.getDate().toString();
        weekDates.push(month + day);
      }

      return weekDates;
    },
    noAvailable() {
      Toast.warn('敬请期待');
    },
    logout() {
      api.logout('wechat').then(() => {
        this.$router.push({
          path: decodeURIComponent('/logout')
        });
      })
    },
    getUserInfo() {
      api.getUserInfo().then(ret => {
        this.avatar = ret.avatar;
        this.userName = ret.nickName;
        this.realName = ret.nickName;
        this.orgName = ret.orgName;
        this.regionName = ret.regionName;
        this.level = ret.level;
        this.nextLevel = ret.nextLevel;
        this.percent = ret.levelPercent;
        this.todayIncrease = ret.todayIncrease;
      })
    }
  },
  mounted() {
    this.getUserInfo();
    this.week = this.getWeekDates();
    api.getUnReadMessageCount()
        .then(n => {
          this.unread = n;
        })
  }
}
</script>

<style lang="scss" scoped>
.myHeader {
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("https://cdn.waityou.online/25de2e2d-beda-5bb2-c170-9aee3acd265f.png");
  padding: 50px 20px 0 20px;
  background-color: #F4F9FC;
  min-height: 100vh;

  .nickName {
    color: black;
  }

  .orgName {
    font-weight: lighter;
    font-size: 14px;
    color: #1E427A;
  }

}

body {
  background-color: white;
}

.friendLink {
  width: 80px;
  height: 80px;
}

.balance-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .balance {
    text-decoration: none;
    text-underline: none;
    color: rgba(128, 128, 128, 0.47);
  }

}

.expHelp {
  color: white;
  font-size: $baseFontSize;
}


.serviceGridItem {
  text-align: center;
  font-size: $baseFontSize;
  color: black;
}

.bothCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 60px;
  height: 60px;
  text-align: center;
  align-items: center;
  align-self: center;
  justify-items: center;
  border-radius: 10%;
}
</style>