<template>
  <div class="AllDaySwitchContainer">
    <nut-row class="bothCenter">
      <nut-col :span="12" class="leftCenter">
        <span class="baseFont">全天</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <nut-switch v-model="enableAllDay" @change="v=>$emit('allDayChange',v)"></nut-switch>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "AllDaySwitch",
  data() {
    return {
      enableAllDay: false
    }
  },
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.AllDaySwitchContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
}
</style>