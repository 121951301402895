<template>
  <div class="CatalogSelectorBarContainer">
    <nut-row className="leftCenter wbg opd16 pd12" @click="showBottom=true">
      <nut-col :span="22" class="leftCenter">
        <img src="https://cdn.waityou.online/82e74fd9-a00d-2b2d-e0f8-4c344a8f8b81.png"
             style="width: 20px;height: 20px"/>
        <nut-ellipsis class="baseFont ml8" v-if="versionInfo" :rows="1" :content="versionInfo.fileName"/>
<!--        <span class="baseFont ml8" v-if="versionInfo">{{versionInfo.fileName}}</span>-->
        <span class="baseFont ml8" style="color: #D0D0D0" v-else>选择数据目录</span>
      </nut-col>
      <nut-col :span="2" class="rightCenter">
        <img src="https://cdn.waityou.online/e88c06b8-6446-b890-f314-5f8f4767a2ab.png"
             style="width: 20px;height: 20px"/>
      </nut-col>
    </nut-row>

  </div>
  <nut-popup position="bottom" :style="{ height: '80%' }"
             round
             v-model:visible="showBottom">
    <CatalogSelector v-if="showBottom" @confirmVersion="onConfirmVersion"/>
  </nut-popup>
</template>

<script>
import CatalogSelector from "@/components/catalog/CatalogSelector";
import api from "@/api";

export default {
  name: "CatalogSelectorBar",
  components: {CatalogSelector},
  data() {
    return {
      showBottom: false,
      versionInfo: null,
    }
  },
  methods: {
    onConfirmVersion(versionId) {
      this.$emit('confirmVersion',versionId)
      this.showBottom = false;
      api.getVersionInfo(versionId)
          .then(ret => {
            this.versionInfo = ret;
          })
    },
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.CatalogSelectorBarContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>