import axios from "../utils/http";
import path from "./path"

const stringFormat = (formatted, args) => {
    for (let i = 0; i < args.length; i++) {
        let regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
    }
    return formatted
}

const api = {
    get(url, query) {
        if (query) {
            return axios.get(path.baseUrl + url, {params: query})
        }
        return axios.get(path.baseUrl + url)
    },
    getUserInfo() {
        return axios.get(path.baseUrl + path.userInfo)
    },
    login(data) {
        return axios.post(path.baseUrl + path.login, data)
    },
    logout(env) {
        return axios.get(path.baseUrl + stringFormat(path.logout, [env]))
    },
    getOssKeySecret() {
        return axios.get(path.baseUrl + path.getOssKeySecret);
    },
    getWechatRedirectUrl(url) {
        return axios.get(path.baseUrl + stringFormat(path.getWechatRedirectUrl, [url]))
    },
    wechatCodeLogin(code) {
        return axios.get(path.baseUrl + stringFormat(path.wechatCodeLogin, [code]))
    },
    getBanners() {
        return axios.get(path.baseUrl + path.getBanners)
    },
    getPostList(query) {
        return axios.get(path.baseUrl + path.getPostList, {params: query})
    },
    getGetComments(query) {
        return axios.get(path.baseUrl + path.getGetComments, {params: query})
    },
    markRead(mid) {
        return axios.get(path.baseUrl + stringFormat(path.markRead, [mid]))
    },

    getHotList() {
        return axios.get(path.baseUrl + path.getHotList)
    },
    thumbComment(commentId) {
        return axios.get(path.baseUrl + stringFormat(path.thumbComment, [commentId]))
    },
    searchPostList(data) {
        return axios.post(path.baseUrl + path.searchPostList, data)
    },
    updateInfo(data) {
        return axios.post(path.baseUrl + path.updateInfo, data)
    },
    getInfiniteLoadingList(i, method, query) {
        if ('GET' == method) {
            return axios.get(path.baseUrl + path.infiniteLoadings[i], {params: query})
        }
        return axios.post(path.baseUrl + path.infiniteLoadings[i], query)
    },
    updateMySign(url) {
        return axios.get(path.baseUrl + stringFormat(path.updateMySign, [url]))
    },
    deleteMySign() {
        return axios.get(path.baseUrl + path.deleteMySign)
    },
    getClockInfo() {
        return axios.get(path.baseUrl + path.getClockInfo)
    },
    updateClockInAddress(data) {
        return axios.post(path.baseUrl + path.updateClockInAddress, data)
    },
    updatePass(data) {
        return axios.post(path.baseUrl + path.updatePass, data)
    },
    bindWechat(token) {
        return axios.get(path.baseUrl + stringFormat(path.wxBindToken, [token]))
    },
    getUnReadMessageCount() {
        return axios.get(path.baseUrl + path.getUnReadMessageCount)
    },
    getMonthCalendar(query) {
        return axios.get(path.baseUrl + path.getMonthCalendar, {params: query})
    },
    getWeather() {
        return axios.get(path.baseUrl + path.getWeather)
    },
    uploadErrorMessage(data) {
        return axios.post(path.baseUrl + path.uploadErrorMessage, data)
    },
    unbindWechat() {
        return axios.get(path.baseUrl + path.unBindWechat)
    },
    getCatalogBaseData() {
        return axios.get(path.baseUrl + path.getCatalogBaseData)
    },
    getDataCatalogOrgList() {
        return axios.get(path.baseUrl + path.getDataCatalogOrgList)
    },
    searchCatalogByName(data) {
        return axios.post(path.baseUrl + path.searchCatalogByName, data);
    },
    getTaskDetail(taskId) {
        return axios.get(path.baseUrl + stringFormat(path.getTaskDetail, [taskId]))
    },
    getUserTree() {
        return axios.get(path.baseUrl + path.getUserTree)
    },
    getDataCatalogGroupByOrg(query) {
        return axios.get(path.baseUrl + path.getDataCatalogGroupByOrg,{params: query})
    },
    getVersionsById(query) {
        return axios.get(path.baseUrl + path.getVersionsById,{params: query})
    },
    getVersionInfo(versionId) {
        return axios.get(path.baseUrl + stringFormat(path.getVersionInfo, [versionId]))
    },
    createTask(data) {
        return axios.post(path.baseUrl + path.createTask, data);
    },
    updateAttachment(data) {
        return axios.post(path.baseUrl + path.updateAttachment, data);
    },
    createMeeting(data) {
        return axios.post(path.baseUrl + path.createMeeting, data);
    },
    getMeetingDetailById(meetingId) {
        return axios.get(path.baseUrl + stringFormat(path.getMeetingDetailById, [meetingId]))
    },
    updateMeetingAttachment(data) {
        return axios.post(path.baseUrl + path.updateMeetingAttachment, data);
    },
    getRemindTimes() {
        return axios.get(path.baseUrl + path.getRemindTimes)
    },
    cancelTask(taskId) {
        return axios.get(path.baseUrl + stringFormat(path.cancelTask, [taskId]))
    },
    completeTask(taskId) {
        return axios.get(path.baseUrl + stringFormat(path.completeTask, [taskId]))
    },
    getCatalogInfoById(catalogId) {
        return axios.get(path.baseUrl + stringFormat(path.getCatalogInfoById, [catalogId]))
    },
    getVersionDownloadInfo(versionId) {
        return axios.get(path.baseUrl + stringFormat(path.getVersionDownloadInfo, [versionId]))
    }
}

export default api;