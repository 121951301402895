<template>
  <div class="MeetingAddressBarViewContainer">
    <img src="https://cdn.waityou.online/8412ca93-61c4-cf2e-d691-c5cd8f05009a.png"
         style="width: 20px;height: 20px"/>
    <span v-if="disabled" style="padding:0;border: none;margin-left: 6px">{{content}}</span>
    <nut-input placeholder="添加会议地点或会议室"
               v-else
               v-model="address"
               @change="onChange"
               style="padding:0;border: none;margin-left: 6px"></nut-input>
  </div>
</template>

<script>
export default {
  name: "MeetingAddressBarView",
  props: {
    disabled:{
      type:Boolean,
      default: false,
    },
    content: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      address: null
    }
  },
  methods: {
    onChange() {
      this.$emit('addressChange', this.address)
    },
    init() {
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style>
input::placeholder {
  color: #D0D0D0;
}
</style>
<style lang="scss" scoped>
.MeetingAddressBarViewContainer {
  display: flex;
  background-color: white;
  padding: 16px;
}
</style>