<template>
  <div class="MeetingCreateViewContainer">
    <nut-row class="bothCenter wbg mt16">
      <nut-col :span="20" class="leftCenter">
        <nut-textarea placeholder="添加会议标题"
                      v-model="meetingName"
                      limit-show
                      max-length="50"
                      class="biggerFont">
        </nut-textarea>
      </nut-col>
      <nut-col :span="4" class="leftCenter" style="color:#999999" @click="meetingTypeVisible=true">
        <span class="smallFont mr4">{{ meetingTypeName }}</span>
        <nut-icon name="rect-down" size="12"></nut-icon>
      </nut-col>
    </nut-row>
    <div style="margin-top: 12px">
      <CreateStartAndEndTime :all-day="allDay"
                             v-if="initTime"
                             :data="initTime"
                             @timeEnd="v=>this.timeEnd=v"
                             @timeStart="v=>this.timeStart=v"/>
    </div>
    <div class="divider"></div>
    <AllDaySwitch @allDayChange="onAllDayChange"/>
    <div style="margin-top: 12px">
      <ParticipantBarView @selectedUserId="onSelectedUser"
                          :userIds="userIds"
                          :content="userIds.length>0?`已选择${userIds.filter(v=>v<0).length}人`:null"/>
    </div>
    <MeetingAddressBarView style="margin-top: 12px" @addressChange="v=>address=v"/>
    <div style="margin-top: 12px">
      <RemindTypeBarView  @dingRemind="onDingRemind" @smsRemind="onSmsRemind"/>
    </div>
    <AttachmentBarView style="margin-top: 12px"
                       v-if="attachments.length>0"
                       @uploadedFiles="onUploadedFiles"
                       :data="attachments" @remove="removeAttachment"/>
    <RepeatBarView style="margin-top: 12px" :data="repeat" v-if="repeat"/>
    <DescriptionBarView :data="description"
                        :key="description"
                        v-if="description" style="margin-top: 12px"/>
    <div style="margin-top: 12px">
      <TaskThreeButton
          :repeatField="repeat == null"
          :attachmentField="attachments.length < 1"
          :descriptionField="description == null"
          @repeat="onRepeat"
          @uploadedFiles="onUploadedFiles"
          @description="onDescription"/>
    </div>
    <nut-row class="bothCenter">
      <nut-button shape="rect brd4 mt32"
                  @click="onSubmit"
                  style="width: 90%;height: 40px;color: white;background: #1E427A;border: none">创建
      </nut-button>
    </nut-row>
  </div>
  <nut-picker
      v-model:visible="meetingTypeVisible"
      :columns="meetingTypes"
      title="类型选择"
      @confirm="confirmMeetingType"
  >
  </nut-picker>
  <TabBar :tab="2"/>
</template>

<script>
import TabBar from "@/components/TabBar";
import CreateStartAndEndTime from "@/components/catalog/create/CreateStartAndEndTime";
import AllDaySwitch from "@/components/catalog/create/AllDaySwitch";
import ParticipantBarView from "@/components/catalog/create/ParticipantBarView";
import RemindTypeBarView from "@/components/catalog/create/RemindTypeBarView";
import TaskThreeButton from "@/components/catalog/create/TaskThreeButton";
import AttachmentBarView from "@/components/catalog/create/AttachmentBarView";
import RepeatBarView from "@/components/catalog/create/RepeatBarView";
import DescriptionBarView from "@/components/catalog/create/DescriptionBarView";
import api from "@/api";
import MeetingAddressBarView from "@/components/meeting/MeetingAddressBarView";

export default {
  name: "MeetingCreateView",
  components: {
    MeetingAddressBarView,
    TabBar,
    CreateStartAndEndTime,
    AllDaySwitch,
    RemindTypeBarView,
    ParticipantBarView,
    TaskThreeButton,
    AttachmentBarView,
    RepeatBarView,
    DescriptionBarView
  },
  data() {
    return {
      address:null,
      initTime: null,
      attachments: [],
      meetingName: null,
      dingRemindType: 0,
      dingRemindTime: 0,
      smsRemindType: 0,
      smsRemindTime:0,
      allDay: false,
      timeEnd: 0,
      timeStart: 0,
      userIds: [],
      versionId: null,
      description: null,
      repeat: null,
      meetingType: 0,
      meetingTypeVisible: false,
      meetingTypeName: '会议',
      meetingTypes: [
        {
          text: '会议',
          value: 0,
        },
        {
          text: '活动',
          value: 1,
        },
      ],
    }
  },
  methods: {
    onDingRemind(type, t) {
      this.dingRemindType = type;
      this.dingRemindTime = t;
    },
    onSmsRemind(type, t) {
      this.smsRemindType = type;
      this.smsRemindTime = t;
    },
    timestampToDateTimeString(timestamp) {
      const dateObj = new Date(timestamp);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      const hours = String(dateObj.getHours()).padStart(2, '0');
      const minutes = String(dateObj.getMinutes()).padStart(2, '0');
      const seconds = String(dateObj.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getNextHourTimestamp() {
      let currentTime = new Date(); // 获取当前时间对象
      let currentHour = currentTime.getHours(); // 获取当前小时数
      // 将小时数加1，处理跨天的情况
      let nextHour = (currentHour + 1) % 24;
      // 设置分钟数和秒数为0，获取下一个整点时间的时间戳
      currentTime.setHours(nextHour, 0, 0, 0);
      return currentTime.getTime();
    },
    onSubmit() {
      api.createMeeting({
        meetingName: this.meetingName,
        address: this.address,
        startTime: this.timeStart,
        endTime: this.timeEnd,
        description: this.description,
        remindType: this.remindType,
        meetingType: this.meetingType,
        checkedUsers: this.userIds,
        allDay: this.allDay,
        attachments: this.attachments,
        repeat: this.repeat,
        dingRemindType: this.dingRemindType,
        dingRemindTime: this.dingRemindTime,
        smsRemindType: this.smsRemindType,
        smsRemindTime: this.smsRemindTime,
      }).then(ret => {
        this.$router.replace('/meeting/detail/' + ret.id)
      })
    },
    confirmMeetingType(op) {
      console.log('confirmMeetingType', op)
      this.meetingType = op.selectedValue[0];
      this.meetingTypeName = op.selectedOptions[0].text;
    },
    onRepeat(op) {
      this.repeat = op;
    },
    removeAttachment(pos) {
      this.attachments.splice(pos, 1)
    },
    onUploadedFiles(files) {
      for (let url of Object.keys(files)) {
        this.attachments.push({
          name: files[url],
          url: url,
        })
      }
    },
    onDescription(desc) {
      this.description = desc;
    },
    onSelectedUser(userIds) {
      this.userIds = userIds;
    },
    onAllDayChange(v) {
      this.allDay = v;
    },
    init() {
      let nextHour = this.getNextHourTimestamp();
      let nextHour2 = nextHour + 3600000;
      this.timeStart = nextHour;
      this.timeEnd = nextHour2;
      this.initTime = {
        timeStart: nextHour,
        timeEnd: nextHour2,
        startTime: this.timestampToDateTimeString(nextHour),
        endTime: this.timestampToDateTimeString(nextHour2),
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.MeetingCreateViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F9FC;
  padding-bottom: 100px;
  min-height: 100vh;
}
</style>