import {createRouter, createWebHashHistory} from 'vue-router'
import LoginView from "@/views/LoginView";
import TaskIndexView from "@/views/task/TaskIndexView";
import MyView from "@/views/MyView";
import LogoutView from "@/views/LogoutView";
import SettingView from "@/views/my/SettingView";
import MessageView from "@/views/MessageView";
import AboutUsView from "@/components/AboutUsView";
import TestView from "@/views/TestView";
import NoLoginView from "@/views/noLoginView";
import NameUpdateView from "@/views/my/NameUpdateView";
import PasswordSetting from "@/views/my/PasswordSetting";
import CatalogView from "@/views/CatalogView";
import TaskPublishView from "@/views/task/TaskPublishView";
import TaskDetailView from "@/views/task/TaskDetailView";
import MeetingCreateView from "@/views/meeting/MeetingCreateView";
import MeetingDetailView from "@/views/meeting/MeetingDetailView";
import MeetingIndexView from "@/views/meeting/MeetingIndexView";
import CatalogDetailView from "@/views/catalog/CatalogDetailView";


const routes = [{
    path: '/', name: 'index', component: TaskIndexView, meta: {
        auth: false, title: '跨层级数据汇聚系统'
    }
}, {
    path: '/index/meeting', name: 'MeetingList', component: MeetingIndexView, meta: {
        auth: false, title: '跨层级数据汇聚系统'
    }
}, {
    path: '/logout', name: 'logout', component: LogoutView, meta: {
        auth: false, title: '已退出登录'
    }
}, {
    path: "/login", name: "login", component: LoginView, meta: {
        auth: false, title: '登录跨层级数据汇聚系统'
    }
}, {
    path: "/nonLogin", name: "noLogin", component: NoLoginView, meta: {
        auth: false, title: '登录跨层级数据汇聚系统'
    }
}, {
    path: '/my', name: 'my', component: MyView, meta: {
        auth: true, title: '我的'
    }
}, {
    path: '/setting', name: 'Setting', component: SettingView, meta: {
        auth: true, title: '我的'
    }
}, {
    path: '/setting/name', name: 'SettingName', component: NameUpdateView, meta: {
        auth: true, title: '更新名字'
    }
}, {
    path: '/setting/pass', name: 'SettingPassword', component: PasswordSetting, meta: {
        auth: true, title: '更新密码'
    }
}, {
    path: '/messages', name: 'MessageView', component: MessageView, meta: {
        auth: true, title: '消息'
    }
}, {
    path: '/aboutus', name: 'AboutUs', component: AboutUsView, meta: {
        auth: true, title: '设置'
    }
}, {
    path: '/catalog', name: 'Catalog', component: CatalogView, meta: {
        auth: true, title: '数据'
    }
}, {
    path: '/catalog/detail/:catalogId', name: 'CatalogDetail', component: CatalogDetailView, meta: {
        auth: true, title: '数据目录详情'
    }
}, {
    path: '/task/publish', name: 'TaskPublish', component: TaskPublishView, meta: {
        auth: true, title: '发布'
    }
}, {
    path: '/test', name: 'Test', component: TestView, meta: {
        auth: true, title: 'Test'
    }
}, {
    path: '/task/detail/:taskId', name: 'TaskDetail', component: TaskDetailView, meta: {
        auth: true, title: '任务详情'
    }
}, {
    path: '/meeting/detail/:meetingId', name: 'MeetingDetail', component: MeetingDetailView, meta: {
        auth: true, title: '会议详情'
    }
},{
    path: '/meeting/create', name: 'CreateMeeting', component: MeetingCreateView, meta: {
        auth: true, title: '发布会议'
    }
},
]

const router = createRouter({
    history: createWebHashHistory(), routes
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
export default router
