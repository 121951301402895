<template>
  <nut-list :height="50" :listData="count" @scroll-up="scrollUp" @scroll-down="scrollDown">
    <template v-slot="{ item, index }">
      <div class="list-item">
        {{item}}
        {{ index }}
      </div>
    </template>
  </nut-list>
</template>

<script>
export default {
  name: "TestView",
  components: {},
  data() {
    return {
      showBasic: true,
      pngSrc:null,
      count:[]
    }
  },
  methods: {
    scrollUp(e){
      console.log('scrollUp',e)
    },
    scrollDown(e){
      console.log('scrollDown',e)
    },
    handleScroll(s) {
      console.log('handleScroll',s)
    }
  },
  mounted() {
    let n = []
    for (let i = 0; i < 100; i++) {
      n.push(i)
    }
    this.count = n
  }
}
</script>

<style lang="scss" scoped>
.TestViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100vw;
  height: 100vh;
}
</style>