const base = {
    baseUrl: "",
    userInfo: "/api/user/info",
    login: "/login/mobileAccountLogin",
    logout: "/api/user/logout?channel=1&env={0}",
    getOssKeySecret: "/api/user/upload/getOssKeySecret",
    getWechatRedirectUrl: "/login/weChatLogin?url={0}",
    wechatCodeLogin: "/login/getUserInfoByCode?code={0}",
    getAuthInfo: "/api/user/auth/getAuthInfo",
    submitAuthInfo: "/api/user/auth/submitAuthInfo",
    getBanners: "/api/user/banner/list",
    createPost: '/api/user/post/createPost',
    getPlatformMessage: '/api/user/message/list',
    getPostList: '/api/user/index/postList',
    markRead: '/api/user/message/markRead?messageId={0}',
    readAllMessage: '/api/user/message/readAll',
    getPostDetail: '/api/user/post/detail?pid={0}',
    getCommentCreatorInfo: '/api/user/comment/creatorInfo?commentId={0}',
    getGetComments: '/api/user/comment/list',
    postComment: '/api/user/comment/post',
    getHotList: '/api/user/hot/hotList',
    thumbPost: '/api/user/thumb/thumb2post?postId={0}',
    thumbComment: '/api/user/thumb/thumb2comment?commentId={0}',
    searchPostList: '/api/user/post/search',
    updateInfo: '/api/user/update',
    feedback: '/api/user/feedback',
    listChatMessage: '/api/user/conversation/list',
    postChatMessage: '/api/user/conversation/send',
    getCommunities: '/api/user/setting/communities',
    getStreetList: '/api/user/setting/streets',
    postEvent: '/api/user/event/postEvent',
    queryPoliceEventList: '/api/user/event/list',
    report: '/api/user/report/report',
    queryReportExist: '/api/user/report/queryReportExist',
    getShopMatterDetail: '/api/user/matter/detail',
    updateShopMatterCheck: '/api/user/matter/update',
    submitCheckReport: '/api/user/matter/submitReport',
    getShopCheckReport: '/api/user/matter/getShopCheckReport?reportId={0}',
    signature2image: '/api/user/signature2image',
    getOpenApiToken: '/login/getOpenApiToken?code={0}',
    updateMySign: '/api/user/updateMySign?url={0}',
    deleteMySign: '/api/user/deleteMySign',
    getClockInfo: '/api/user/clock/clock',
    updateClockInAddress: '/api/user/clock/updateClockInAddress',
    getDataSummary: '/api/user/data/query/summary',
    getEarlierMessage: '/api/user/conversation/earlierList',
    getEventDetail: '/api/user/event/detail?eventId={0}',
    closePoliceEvent: '/api/user/event/close?eventId={0}',
    getActiveEvents: '/api/user/event/getActiveEvents',
    updatePass: '/api/user/updatePass',
    wxBindToken: '/api/user/wxBindToken?token={0}',
    unBindWechat: '/api/user/unbindWx',
    getUnReadMessageCount: '/api/user/message/countUnRead',
    getMyStatisticData: '/api/user/statistic/myData',
    getStatisticRank: '/api/user/statistic/rank',
    getMonthCalendar: '/api/user/statistic/getMonthCalendar',
    queryShopStatisticDataByDay: '/api/user/statistic/queryShopStatisticDataByDay',
    updateShopCheckReport: '/api/user/matter/updateShopCheckReport?reportId={0}',
    getReportDetail: '/api/user/report/detail?id={0}',
    getWeather: '/api/user/setting/weather',
    queryShopStatisticDataByYear: '/api/user/statistic/queryShopStatisticDataByYear',
    queryShopStatisticDataByMonth: '/api/user/statistic/queryShopStatisticDataByMonth',
    queryShopStatisticDataByDateRange: '/api/user/statistic/queryShopStatisticDataByDateRange',
    uploadErrorMessage: '/api/user/setting/uploadErrorMessage',
    covertGPS: '/api/user/setting/amap/convertGps',
    getChatList: '/api/user/conversation/getChatList',
    getCatalogBaseData: '/api/user/statistic/baseData',
    getDataCatalogOrgList: '/api/open/catalog/getDataCatalogOrgList',
    searchCatalogByName: '/api/open/catalog/searchCatalogByName',
    getTaskDetail: '/api/user/task/detail?taskId={0}',
    getUserTree: '/api/user/org/getUserTree',
    getDataCatalogGroupByOrg: '/api/open/catalog/getDataCatalogGroupByOrg',
    getVersionsById: '/api/user/catalog/getVersionsById',
    getVersionInfo: '/api/user/catalog/getVersionInfo?versionId={0}',
    createTask: '/api/user/task/createTask',
    updateAttachment: '/api/user/task/updateAttachment',
    createMeeting: '/api/user/meeting/create',
    getMeetingDetailById: '/api/user/meeting/getMeetingDetailById?meetingId={0}',
    updateMeetingAttachment: '/api/user/meeting/updateAttachment',
    getRemindTimes: '/api/open/setting/getRemindTimes',
    cancelTask: '/api/user/task/cancel?taskId={0}',
    completeTask: '/api/user/task/complete?taskId={0}',
    getCatalogInfoById: '/api/user/catalog/getCatalogInfoById?catalogId={0}',
    getVersionDownloadInfo: '/api/user/catalog/getVersionDownloadInfo?versionId={0}',

    infiniteLoadings: {
        1:'/api/user/task/list',
        2:'/api/user/message/list',
        3:'/api/user/meeting/list',
    }
}

export default base;