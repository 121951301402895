<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="cachedPages">
      <component :is="Component" :key="$route.name"></component>
    </keep-alive>
  </router-view>
</template>


<script>
import api from "@/api";

export default {
  name: "App",
  mounted() {
    // let fm = localStorage.getItem('font-mode');
    // if (fm === 'old') {
    //   document.getElementsByTagName('body')[0].style.setProperty('--base-font-size', '18px');
    //   document.getElementsByTagName('body')[0].style.setProperty('--smaller-font-size', '14px');
    // } else {
    document.getElementsByTagName('body')[0].style.setProperty('--bigger-font-size', '18px');
    document.getElementsByTagName('body')[0].style.setProperty('--base-font-size', '16px');
    document.getElementsByTagName('body')[0].style.setProperty('--smaller-font-size', '14px');
    document.getElementsByTagName('body')[0].style.setProperty('--mini-font-size', '12px');
    document.getElementsByTagName('body')[0].style.setProperty('--min-font-size', '10px');
    // }

    window.onerror = function (message, source, lineno, colno, error) {
      // 发送错误信息到服务器
      var deviceInfo = {
        userAgent: navigator.userAgent,  // 浏览器用户代理字符串
        platform: navigator.platform,    // 客户端操作系统平台
        screenWidth: window.screen.width,  // 屏幕宽度
        screenHeight: window.screen.height  // 屏幕高度
      };
      var errorData = {
        message: message,
        source: source,
        lineno: lineno,
        colno: colno,
        error: error,
        device: deviceInfo,
        url: window.location.href,
      };
      api.uploadErrorMessage(errorData);
    };

  },
  computed: {
    cachedPages() {
      return this.$store.state.cachePages;
    }
  },
}
</script>

<style lang="scss">
body {
  padding: 0;
  margin: 0;
  background-color: white;
}
</style>