<template>
  <nut-row class="miniFont wbg"
           style="display: flex;position:fixed;bottom:0;padding-bottom: 10px;padding-top:10px;align-items: flex-end">
    <nut-col :span="4" class="colFlex bothCenter" @click="$router.push('/')">
      <img src="https://cdn.waityou.online/cf13280e-b435-de68-e5e2-146c2d3f65e9.png" v-if="tab==0" class="tabIcon"/>
      <img src="https://cdn.waityou.online/cac62d4d-ba1b-773a-3ae6-6e53576de0bd.png" v-else class="tabIcon"/>
      <span :class="tab==0?'active':'unActive'">首页</span>
    </nut-col>
    <nut-col :span="4" class="colFlex bothCenter" @click="$router.push('/catalog')">
      <img src="https://cdn.waityou.online/b8074b8d-aa3f-0f78-fca1-2e5322227fa0.png" v-if="tab==1" class="tabIcon"/>
      <img src="https://cdn.waityou.online/eabb56e0-fb93-22b0-0b66-3848cf62715a.png" v-else class="tabIcon"/>
      <span :class="tab==1?'active':'unActive'">数据</span>
    </nut-col>
    <nut-col :span="8" class="colFlex bothCenter" @click="publishVisible=true">
      <img src="https://cdn.waityou.online/9c720684-506e-71e6-4ca9-3d6a4243d9ad.png"
           class="midTabIcon"/>
      <span :class="tab==2?'active':'unActive'">发布</span>
    </nut-col>
    <nut-col :span="4" class="colFlex bothCenter" @click="$router.push('/messages')">
      <img src="https://cdn.waityou.online/a05c7afe-1e34-aa02-711d-cace530897fc.png" v-if="tab==3" class="tabIcon"/>
      <img src="https://cdn.waityou.online/2272fa0b-7274-89d4-a654-bfaf4f8f77de.png" v-else class="tabIcon"/>
      <span :class="tab==3?'active':'unActive'">消息</span>
    </nut-col>
    <nut-col :span="4" class="colFlex bothCenter" @click="$router.push('/my')">
      <img src="https://cdn.waityou.online/bd4928f5-92be-7109-00aa-8721de07f91d.png" v-if="tab==4" class="tabIcon"/>
      <img src="https://cdn.waityou.online/f41a50b2-8d4e-2db6-43f7-2c71aca04eab.png" v-else class="tabIcon"/>
      <span :class="tab==4?'active':'unActive'">我的</span>
    </nut-col>

    <nut-popup v-model:visible="publishVisible" style="background: transparent">
      <div class="bothCenter colFlex">
        <span class="biggerFont boldFont" style="color:white">您要发布</span>
      </div>
      <nut-row class="bothCenter mt32" style="width: 60vw">
        <nut-col :span="12" class="leftCenter">
          <nut-button @click="go('/meeting/create')"
                      style="border: none;background: #1E427A;color:white;border-radius: 4px;width: 80px"
                      shape="rect">会议
          </nut-button>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <nut-button @click="go('/task/publish')"
                      style="border: none;background: #1E427A;color:white;border-radius: 4px;width: 80px"
                      shape="rect">任务
          </nut-button>
        </nut-col>
      </nut-row>
    </nut-popup>
  </nut-row>
</template>

<script>
export default {
  name: "TabBar",
  data() {
    return {
      active: 0,
      publishVisible: false,
    }
  },
  props: {
    tab: {
      type: Number,
      default: 0
    },
  },
  mounted() {
    this.active = this.tab;
  },
  watch: {
    tab(n) {
      this.active = n;
    }
  },
  methods: {
    go(path) {
      this.publishVisible = false;
      this.$router.push(path);
    }
  },
}
</script>

<style lang="scss">
.active {
  color: #1E427A;
}

.unActive {
  color: #A7A7A7;
}

.tabIcon {
  width: 20px;
  height: 20px;
  margin-bottom: 3px;
}

.midTabIcon {
  width: 36px;
  height: 36px;
  margin-bottom: 5px;
  position: fixed;
  bottom: 22px;
  left: calc(50vw - 23px);
  border-radius: 30px;
  border: white 5px solid;
}
</style>