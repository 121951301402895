import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/styles/themes/default.scss";
import './icons'
import SvgIcon from "@/components/SvgIcon";
import VueSignaturePad from "vue-signature-pad";
import vue3PhotoPreview from 'vue3-photo-preview';
import 'vue3-photo-preview/dist/index.css';

router.beforeEach((to, from, next) => {
    if (to.meta.auth) { // 判断该路由是否需要登录权限
        if (localStorage.getItem('access_token')) { //判断本地是否存在access_token
            next();
        } else {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    } else {
        next();
    }
    /*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
    if (to.fullPath === "/login") {
        if (localStorage.getItem('access_token')) {
            next({
                path: from.fullPath
            });
        } else {
            next();
        }
    }
});

const app = createApp(App);
app.component('svg-icon', SvgIcon);
app.use(NutUI).use(store).use(router).use(VueSignaturePad)
    .use(vue3PhotoPreview).mount('#app')
