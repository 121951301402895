<template>
  <div class="MeetingIndexViewContainer">
    <IndexWeekDay/>
    <IndexMeetingList/>
  </div>
  <TabBar :tab="0"/>
</template>

<script>
import TabBar from "@/components/TabBar";
import IndexWeekDay from "@/components/index/IndexWeekDay";
import IndexMeetingList from "@/components/meeting/IndexMeetingList";

export default {
  name: "MeetingIndexView",
  components:{IndexMeetingList, TabBar,IndexWeekDay},
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.MeetingIndexViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>