<template>
  <div class="ParticipantListViewContainer" @click="remindTypeVisible=true">
    <nut-row className="leftCenter wbg opd16 pd12">
      <nut-col :span="22" class="leftCenter">
        <img src="https://cdn.waityou.online/bf136db7-43b0-6f0f-2197-6472232ef977.png"
             style="width: 20px;height: 20px"/>
        <span class="baseFont ml8" v-if="isSet">已设置</span>
        <span class="baseFont ml8" style="color: #D0D0D0" v-else>请选择</span>
      </nut-col>
      <nut-col :span="2" class="rightCenter">
        <img src="https://cdn.waityou.online/e88c06b8-6446-b890-f314-5f8f4767a2ab.png"
             style="width: 20px;height: 20px"/>
      </nut-col>
    </nut-row>
  </div>
  <nut-popup position="bottom" round
             v-model:visible="remindTypeVisible">
    <RemindChannelSelector
        :data="config"
        @dingRemind="onDingRemind"
        @smsRemind="onSmsRemind"/>
  </nut-popup>
</template>

<script>
import RemindChannelSelector from "@/components/reminder/RemindChannelSelector";

export default {
  name: "RemindTypeBarView",
  components: {RemindChannelSelector},
  props: {
    remindType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      remindTypeVisible: false,
      isSet:false,
      config:{
        dingRemindType: 0,
        dingRemindTime: 0,
        smsRemindType: 0,
        smsRemindTime:0,
      },
    }
  },
  methods: {
    onDingRemind(type, t) {
      this.config.dingRemindType = type;
      this.config.dingRemindTime = t;
      this.isSet = true;
      this.$emit('dingRemind', type, t);
    },
    onSmsRemind(type, t) {
      this.config.smsRemindType = type;
      this.config.smsRemindTime = t;
      this.isSet = true;
      this.$emit('smsRemind', type, t);
    },
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ParticipantListViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>