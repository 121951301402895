<template>
  <div class="CatalogViewContainer">
    <nut-row class="bothCenter">
      <nut-col :span="10" class="leftCenter">
        <CatalogStatisticCard
            :data="{
              name:'数据目录总数',
              number:baseData.totalCatalog,
              unit:'份',
              fileSize:baseData.totalCatalogFileSize,
              yesterday:baseData.yesterdayCreatedCnt
            }"
            style="width: 100%;padding: 0 0 10px 0"/>
      </nut-col>
      <nut-col :span="14" class="rightCenter" >
        <CatalogStatisticCard
            :data="{
              name:'通用子目录/专有子目录',
              number:baseData.commonAndSpecialCnt,
              unit:'条',
              fileSize:'&nbsp;',
              yesterday:baseData.yesterdayCommonAndSpecialCnt
            }"
            style="width: 100%;padding: 0 0 10px 10px"/>
      </nut-col>
    </nut-row>
    <nut-input left-icon="search2"
               placeholder="搜索部门、目录名称"
               @keyup.enter="search"
               @change="search"
               @clear="clearSearch"
               v-model="searchContent"
               clearable
               style="justify-items: center"/>
    <div style="padding: 4px 0">
      <CatalogSearchResult v-for="(sl,sli) in searchList" :key="sli" :data="sl"/>
      <CatalogInfoCard v-for="org in dataCatalogOrgList"
                       :expand="org.orgId == expandId"
                       @click="onClickCatalogInfoCard(org)"
                       :key="org.orgId" :data="org"/>
    </div>
  </div>
  <TabBar :tab="1"/>
</template>

<script>
import CatalogStatisticCard from "@/components/catalog/CatalogStatisticCard"
import TabBar from "@/components/TabBar";
import CatalogInfoCard from "@/components/catalog/CatalogInfoCard";
import api from "@/api";
import CatalogSearchResult from "@/components/catalog/CatalogSearchResult";

export default {
  name: "CatalogView",
  components: {CatalogSearchResult, CatalogInfoCard, TabBar, CatalogStatisticCard},
  data() {
    return {
      expandId:0,
      searchContent:null,
      baseData: {},
      dataCatalogOrgList:[],
      searchList:[],
    }
  },
  methods: {
    onClickCatalogInfoCard(catalog){
      if(this.expandId > 0){
        this.expandId = 0;
        return
      }
      this.expandId = catalog.orgId;
    },
    clearSearch(){
      this.searchContent = '';
      this.searchList = [];
    },
    search(){
      if(!this.searchContent){
        this.clearSearch()
        return
      }
      api.searchCatalogByName({
        name: this.searchContent
      }).then(ret=>{
        if(ret){
          this.searchList = ret;
        }
      })
    },
    init() {
      api.getCatalogBaseData()
          .then(ret=>{
            this.baseData = ret;
          })
      api.getDataCatalogOrgList()
          .then(ret=>{
            this.dataCatalogOrgList = ret;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style>
input::placeholder {
  color: #aab2bd !important;
}
</style>
<style lang="scss" scoped>
.CatalogViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F9FC;
  min-height: calc(100vh - 32px);
  padding:10px;



  .nut-input{
    padding: 10px;

  }
}
</style>