<template>
  <div class="TaskThreeButtonContainer" v-if="attachmentField||repeatField||descriptionField">
    <nut-row class="bothCenter">
      <nut-col :span="8"
               v-if="repeatField"
               class="bothCenter colFlex" @click="repeatVisible=true">
        <img src="https://cdn.waityou.online/90d73883-3441-a753-0fce-d9205bd3b9fc.png"
             style="width: 24px;height: 24px"/>
        <span class="miniFont mt4" style="color: #999999">重复</span>
      </nut-col>
      <nut-col :span="8"
               v-if="attachmentField"
               class="bothCenter colFlex">
        <CustomOssUploader :accept="'*/*'"
                           :key="uploaderVersion"
                           @onUploadedFiles="onUploadedFiles">
          <img src="https://cdn.waityou.online/ad4b04d2-0eb9-6eec-c791-e31355213712.png"
               style="width: 24px;height: 24px"/>
        </CustomOssUploader>
        <span class="miniFont " style="color: #999999">附件</span>
      </nut-col>
      <nut-col :span="8"
               v-if="descriptionField"
               class="bothCenter colFlex" @click="descriptionVisible=true">
        <img src="https://cdn.waityou.online/c5c17608-91ca-eb3e-2da2-40b54097a97a.png"
             style="width: 24px;height: 24px"/>
        <span class="miniFont mt4" style="color: #999999">描述</span>
      </nut-col>
    </nut-row>
  </div>

  <nut-popup position="bottom" round
             v-model:visible="descriptionVisible">
    <div class="colFlex pd16">
      <nut-row className="bothCenter ">
        <nut-col :span="12" class="leftCenter">
          <span class="smallFont" style="color: #999999">添加描述</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <nut-button style="background: #1E427A;color: white;border: none;border-radius: 4px"
                      @click="descriptionVisible=false"
                      size="small" shape="rect">确定
          </nut-button>
        </nut-col>
      </nut-row>
      <nut-textarea placeholder="请输入描述"
                    class="mt16"
                    v-model="description"
                    :rows="5"
                    limit-show
                    max-length="255"
                    @change="onDescriptionChange"
                    style="border: #999999 1px solid;padding: 16px"></nut-textarea>
    </div>
  </nut-popup>
  <nut-picker
      v-model:visible="repeatVisible"
      :columns="columns"
      title="重复计划"
      @confirm="confirmRepeatPlan"
  >
  </nut-picker>
</template>

<script>
import CustomOssUploader from "@/components/CustomOssUploader"

export default {
  name: "TaskThreeButton",
  components: {CustomOssUploader},
  props: {
    repeatField: {
      type: Boolean,
      default: false
    },
    attachmentField: {
      type: Boolean,
      default: false
    },
    descriptionField: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      uploaderVersion: 0,
      repeatVisible: false,
      descriptionVisible: false,
      description: null,
      columns: [
        {
          text: '不重复',
          value: 'NO_REPEAT',
        },
        {
          text: '每个工作日',
          value: 'WORK_DAY',
        },
        {
          text: '每周',
          value: 'EVERY_WEEK',
        },
        {
          text: '每月',
          value: 'EVERY_MONTH',
        },
      ],
    }
  },
  methods: {
    confirmRepeatPlan(op) {
      this.$emit('repeat', op.selectedValue[0])
    },
    onUploadedFiles(files, fileWithNames) {
      this.uploaderVersion++;
      this.$emit('uploadedFiles', fileWithNames)
    },
    onDescriptionChange() {
      this.$emit('description', this.description)
    },
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.TaskThreeButtonContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px 0;
}
</style>