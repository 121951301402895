<template>
  <div class="IndexTaskListContainer">
    <nut-row class="bothCenter smallFont">
      <nut-col :span="12" class="leftCenter">
        <div class="leftCenter"
             style="color: #1E427A;border: #1E427A 1px solid;padding: 3px 10px;
border-radius: 20px 0 0 20px">
          <img src="https://cdn.waityou.online/a9cfb35d-1afe-fc6e-8f16-7bbf576cbc0c.png"
               style="width: 16px;height: 16px"/>
          <span>&nbsp;任务</span>
        </div>
        <div class="leftCenter"
             @click="$router.push('/index/meeting')"
             style="color: #1E427A;
             border-top: #1E427A 1px solid;
             border-bottom: #1E427A 1px solid;
             border-right: #1E427A 1px solid;
             padding: 3px 5px;
             border-radius: 0 20px 20px 0">
          <img src="https://cdn.waityou.online/59e19c9d-e17c-1d21-1669-f687f77f2b1f.png"
               style="width: 16px;height: 16px"/>
          <span>&nbsp;</span>
        </div>
      </nut-col>
      <nut-col :span="12" class="rightCenter"
               @click="taskTypeSelectorVisible=true"
               style="color: #666666">
        <span class="mr16">{{ taskTypeName }}</span>
        <nut-icon name="rect-down" size="12"></nut-icon>
      </nut-col>
    </nut-row>
    <InfiniteLoadingList :query-id="1" :init-query="{'type':taskType}">
      <template v-slot="data">
        <IndexTaskItem :data="data.data" @click="$router.push('/task/detail/'+data.data.id)"/>
      </template>
    </InfiniteLoadingList>
  </div>

  <nut-picker
      v-model:visible="taskTypeSelectorVisible"
      :columns="taskTypes"
      title="任务类型"
      @confirm="confirmTaskType"
  >
  </nut-picker>
</template>

<script>
import InfiniteLoadingList from "@/components/InfiniteLoadingList";
import IndexTaskItem from "@/components/index/IndexTaskItem";

export default {
  name: "IndexTaskList",
  components: {IndexTaskItem, InfiniteLoadingList},
  data() {
    return {
      taskTypeSelectorVisible: false,
      taskType: null,
      taskTypeName: '全部',
      taskTypes: [
        {text: '全部', value: -1},
        {text: '当天', value: 0},
        {text: '数据上报', value: 1},
        {text: '常态化', value: 2},
        {text: '专项', value: 3},
        {text: '其他', value: 4},
      ],
    }
  },
  methods: {
    confirmTaskType(op) {
      this.taskType = op.selectedValue[0];
      this.taskTypeName = op.selectedOptions[0].text;
    },
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.IndexTaskListContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  padding-bottom: 80px;
}
</style>