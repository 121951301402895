<template>
  <div class="TaskPublishViewContainer">
    <div class="bothCenter wd100 vpd12">
      <div class="bothCenter wd20" v-for="(t,ti) in types"
           @click="tab=ti"
           :key="ti">
        <span class="smallFont vpd4"
              :style="{borderBottom: tab == ti?'#1E427A 3px solid':'#F4F9FC 3px solid',
              color:tab == ti?'#1E427A':'black'}">{{t}}</span>
      </div>
    </div>
    <DataReportTaskCreateView v-if="tab==1"/>
    <NonDataReportCreateView v-else :taskType="tab"/>
  </div>
  <TabBar :tab="2"/>
</template>

<script>
import TabBar from "@/components/TabBar";
import NonDataReportCreateView from "./create/NonDataReportCreateView";
import DataReportTaskCreateView from "@/views/task/create/DataReportTaskCreateView";

export default {
  name: "TaskPublishView",
  components: {DataReportTaskCreateView, TabBar, NonDataReportCreateView},
  data() {
    return {
      tab: 1,
      types:[
          '当天',
          '数据上报',
          '常态化',
          '专项',
          '其他',
      ]
    }
  },
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.TaskPublishViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F9FC;
  height: 100vh;

  .tabPane {
    background: transparent;
    padding: 0;
  }
}
</style>