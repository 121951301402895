<template>
  <div class="TaskDetailViewContainer">
    <nut-row className="bothCenter pd16">
      <nut-col :span="12" class="colFlex">
        <span style="font-size: 18px;">{{ task.taskName }}</span>
        <div class="leftCenter mt8">
          <img src="https://cdn.waityou.online/6e925533-ffab-3da6-24f0-32f4a0d82860.png"
               style="width: 18px;height: 18px">
          <span class="smallFont ml8">{{ types[task.taskType] }}</span>
        </div>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <span class="miniFont brd4"
              v-if="['LAUNCH','WAIT_EDIT'].includes(task.state)"
              style="color:white;background: #B6B5B5;padding: 2px 6px">未开始</span>
        <span class="miniFont brd4"
              v-if="['EDITING','REVIEW'].includes(task.state)"
              style="color:white;background: #27BF94;padding: 2px 6px">进行中</span>
        <span class="miniFont brd4"
              v-if="['STORED','SHARE'].includes(task.state)"
              style="color:white;background: #1E427A;padding: 2px 6px">已完成</span>
        <span class="miniFont brd4"
              v-if="['CANCELED'].includes(task.state)"
              style="color:white;background: #B6B5B5;padding: 2px 6px">已取消</span>
      </nut-col>
    </nut-row>
    <CreateStartAndEndTime :data="task" v-if="task"/>
    <div class="divider"></div>
    <ParticipantBarView :content="participantsName"/>
    <div class="divider"></div>
    <nut-row className="leftCenter wbg opd16 pd12" v-if="task.taskType == 'DATA_REPORT'">
      <nut-col :span="22" class="leftCenter">
        <img src="https://cdn.waityou.online/282d9baf-c331-6c8b-8cbe-d1dea18438d0.png"
             style="width: 20px;height: 20px"/>
        <span class="ml8" style="color:#1E427A">{{ version.fileName }}</span>
      </nut-col>
      <nut-col :span="2" class="rightCenter">
        <img src="https://cdn.waityou.online/e88c06b8-6446-b890-f314-5f8f4767a2ab.png"
             style="width: 20px;height: 20px"/>
      </nut-col>
    </nut-row>

    <template v-if="task.attachments">
      <div class="divider"></div>
      <AttachmentBarView :data="task.attachments"
                         @uploadedFiles="onUploadedFiles"
                         @remove="removeAttachment"
                         :disabled="task.owner"/>
    </template>
    <template v-if="task.description">
      <div class="divider"></div>
      <nut-row className="leftCenter wbg opd16 pd12">
        <img src="https://cdn.waityou.online/4c9c5d4c-81f3-57a4-cbe7-aa1bdd03d8a9.png"
             style="width: 20px;height: 20px"/>
        <span class="ml8">{{ task.description }}</span>
      </nut-row>
    </template>
  </div>
  <nut-row style="position:fixed;bottom: 10px;" :gutter="10" v-if="owner">
    <nut-col :span="12" class="rightCenter" v-if="step <2">
      <nut-button style="background: #B8B8B8;color:white;width: 90%;border-radius: 4px" shape="rect"
                  @click="cancelTask">取消
      </nut-button>
    </nut-col>
    <nut-col :span="12" class="leftCenter" v-if="step < 4">
      <nut-button style="background: #1E427A;color: white;width: 90%;border-radius: 4px" shape="rect"
                  @click="completeTask">完成</nut-button>
    </nut-col>
  </nut-row>
</template>

<script>
import api from "@/api";
import CreateStartAndEndTime from "@/components/catalog/create/CreateStartAndEndTime"
import ParticipantBarView from "@/components/catalog/create/ParticipantBarView";
import AttachmentBarView from "@/components/catalog/create/AttachmentBarView"

export default {
  name: "TaskDetailView",
  components: {ParticipantBarView, CreateStartAndEndTime, AttachmentBarView},

  data() {
    return {
      owner: false,
      step: 0,
      taskId: this.$route.params.taskId,
      task: {
        state: 0,
      },
      version: {},
      participants: [],
      participantsName: null,
      types: {
        TheDay: "当天",
        DATA_REPORT: "数据上报",
        NORMALIZATION: "常态化",
        SPECIAL: "专项",
        OTHER: "其他",
      }
    }
  },
  methods: {
    cancelTask() {
      api.cancelTask(this.taskId)
          .then(() => this.init())
    },
    completeTask() {
      api.completeTask(this.taskId)
          .then(() => this.init())
    },
    onUploadedFiles(files) {
      if (!this.task.attachments) {
        this.task.attachments = [];
      }
      for (let url of Object.keys(files)) {
        this.task.attachments.push({
          name: files[url],
          url: url,
        })
      }
      api.updateAttachment({
        taskId: this.taskId,
        attachments: this.task.attachments
      }).then(() => {
        this.init()
      })
    },
    removeAttachment(pos) {
      this.task.attachments.splice(pos, 1)
      api.updateAttachment({
        taskId: this.taskId,
        attachments: this.task.attachments
      }).then(() => {
        this.init()
      });
    },
    init() {
      api.getTaskDetail(this.taskId)
          .then(ret => {
            this.task = ret.task;
            this.version = ret.version;
            this.participants = ret.participants;
            this.owner = ret.owner;
            this.step = ret.step;

            this.participantsName = ret.participants.map(p => {
              return p.me ? '我' : p.userInfo.nickName
            }).join(',')
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.TaskDetailViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F9FC;
  height: 100vh;
}
</style>