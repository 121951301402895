<template>
  <div class="CatalogDetailViewContainer">
    <span>{{ catalog.name }}</span>
    <div class="colFlex wbg smallFont pd12 mt12" style="color: #999999">
      <span>所属处室：杭州消防救援支队/{{ catalog.orgName }}</span>
      <nut-row class="bothCenter mt8">
        <nut-col :span="12" class="leftCenter">
          <span>数据属性：{{ catalog.dataPropertyTypeName }}</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <span>子目录数量：{{ fields?.length }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter mt8">
        <nut-col :span="12" class="leftCenter">
          <span>数据质量：{{ qualities[catalog.dataQuality] }}</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <span>更新频次：年</span>
        </nut-col>
      </nut-row>
      <span class="mt8">创建时间：{{ catalog?.createdTime?.substring(0, 10) }}</span>
      <span class="mt8">更新时间：{{ catalog?.updatedTime?.substring(0, 10) }}</span>
      <span class="mt8">管理员：{{ owner.nickName }}</span>
      <span class="mt8">联系人：{{ owner.nickName }}    联系电话：{{ owner.phone }}</span>
    </div>
    <span class="mt12">目录下载</span>
    <div class="colFlex wbg pd12 smallFont mt12">
      <div class="colFlex" v-for="v in versions" :key="v.id">
        <span>{{ v.fileName }}</span>
        <span style="color: #1388ff;margin-top: 6px" @click="downloadVersion(v.id)">下载</span>
      </div>
    </div>
    <span class="mt12">字段列表</span>
    <nut-table :columns="columns" :data="fields" class="wbg mt12 miniFont"></nut-table>
  </div>
<!--  <nut-row class="bothCenter wbg" style="position: fixed;bottom: 0">-->
<!--    <nut-col :span="12" class="bothCenter">-->
<!--      <span>Text</span>-->
<!--    </nut-col>-->
<!--    <nut-col :span="12" class="bothCenter">-->
<!--      <span>Text</span>-->
<!--    </nut-col>-->
<!--  </nut-row>-->
</template>

<script>
import api from "@/api";

export default {
  name: "CatalogDetailView",
  data() {
    return {
      columns:[
        {
          title: '名称',
          key: 'fieldName'
        },
        {
          title: '数据类型',
          key: 'fieldDataType'
        },
        {
          title: '描述',
          key: 'description'
        },
        {
          title: '示例',
          key: 'example'
        },
      ],
      catalogId: this.$route.params.catalogId,
      catalog: {},
      fields: [],
      isOwner: false,
      owner: {},
      versions: [],
      qualities: {
        NORMAL: '普通',
        GOOD: '较好',
        PERFECT: '高',
      },
      updateFrequencies: {
        DAY: "及时",
        WEEK: "每周",
        MONTH: "每月",
        MONTH3: "每季度",
        HALF_YEAR: "每半年",
        YEAR: "每年",
        NOT_SURE: "不定时",
      },
    }
  },
  methods: {
    downloadVersion(versionId) {
      api.getVersionDownloadInfo(versionId)
          .then(ret => {
            window.open(ret.sourceFileUrl, '_blank')
          })
    },
    init() {
      api.getCatalogInfoById(this.catalogId)
          .then(ret => {
            this.catalog = ret.catalog;
            this.fields = ret.fields;
            this.isOwner = ret.isOwner;
            this.versions = ret.versions;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.CatalogDetailViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;
  padding: 12px 12px 100px 12px;
  min-height: calc(100vh - 24px);
}
</style>