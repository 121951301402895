<template>
  <div class="MeetingDetailViewContainer">
    <nut-row className="bothCenter pd16">
      <nut-col :span="12" class="colFlex">
        <span style="font-size: 18px;">{{ meeting.meetingName }}</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <span class="miniFont brd4"
              v-if="meeting.state == 'LAUNCH' || meeting.state == 'WAIT'"
              style="color:white;background: #B6B5B5;padding: 2px 6px">未开始</span>
        <span class="miniFont brd4"
              v-if="meeting.state == 'MEETING'"
              style="color:white;background: #27BF94;padding: 2px 6px">进行中</span>
        <span class="miniFont brd4"
              v-if="meeting.state == 'COMPLETE'"
              style="color:white;background: #1E427A;padding: 2px 6px">已完成</span>
        <span class="miniFont brd4"
              v-if="meeting.state == 'CANCELED'"
              style="color:white;background: #B6B5B5;padding: 2px 6px">已取消</span>
      </nut-col>
    </nut-row>
    <CreateStartAndEndTime :data="meeting" v-if="meeting"/>
    <div class="divider"></div>
    <ParticipantBarView :content="participantsName"/>
    <template v-if="meeting.attachments">
      <div class="divider"></div>
      <AttachmentBarView :data="meeting.attachments"
                         @uploadedFiles="onUploadedFiles"
                         @remove="removeAttachment"
                         :disabled="meeting.owner"/>
    </template>
    <div class="divider"></div>
    <MeetingAddressBarView :content="meeting.address" disabled/>
    <div class="divider"></div>
    <nut-row className="leftCenter wbg opd16 pd12">
      <img src="https://cdn.waityou.online/4c9c5d4c-81f3-57a4-cbe7-aa1bdd03d8a9.png"
           style="width: 20px;height: 20px"/>
      <span class="ml8">{{ meeting.description }}</span>
    </nut-row>
  </div>
  <nut-row style="position:fixed;bottom: 10px;" :gutter="10" v-if="owner">
    <nut-col :span="12" class="rightCenter" v-if="step < 3">
      <nut-button style="background: #B8B8B8;color:white;width: 90%;border-radius: 4px" shape="rect">取消</nut-button>
    </nut-col>
    <nut-col :span="12" class="leftCenter" v-if="step < 3">
      <nut-button style="background: #1E427A;color: white;width: 90%;border-radius: 4px" shape="rect">结束
      </nut-button>
    </nut-col>
  </nut-row>
</template>

<script>
import api from "@/api";
import CreateStartAndEndTime from "@/components/catalog/create/CreateStartAndEndTime"
import ParticipantBarView from "@/components/catalog/create/ParticipantBarView";
import AttachmentBarView from "@/components/catalog/create/AttachmentBarView"
import MeetingAddressBarView from "@/components/meeting/MeetingAddressBarView";

export default {
  name: "MeetingDetailView",
  components: {MeetingAddressBarView, CreateStartAndEndTime, ParticipantBarView, AttachmentBarView},
  data() {
    return {
      meetingId: this.$route.params.meetingId,
      meeting: {},
      participants: [],
      participantsName: null,
      step:0,
      owner:false,
      types: {
        0: "会议",
        1: "活动",
      }
    }
  },
  methods: {
    onUploadedFiles(files) {
      if (!this.meeting.attachments) {
        this.task.attachments = [];
      }
      for (let url of Object.keys(files)) {
        this.meeting.attachments.push({
          name: files[url],
          url: url,
        })
      }
      api.updateMeetingAttachment({
        meetingId: this.meetingId,
        attachments: this.meeting.attachments
      }).then(() => {
        this.init()
      })
    },
    removeAttachment(pos) {
      this.meeting.attachments.splice(pos, 1)
      api.updateMeetingAttachment({
        meetingId: this.meetingId,
        attachments: this.meeting.attachments
      }).then(() => {
        this.init()
      });
    },
    init() {
      api.getMeetingDetailById(this.meetingId)
          .then(ret => {
            this.meeting = ret.meeting;
            this.participants = ret.participants;
            this.step = ret.step;
            this.owner = ret.owner;

            this.participantsName = ret.participants.map(p => {
              return p.me ? '我' : p.userInfo.nickName
            }).join(',')
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.MeetingDetailViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F9FC;
  height: 100vh;
}
</style>