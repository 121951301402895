<template>
  <div class="CurrentDayCreateViewContainer">
    <nut-textarea placeholder="添加任务标题"
                  v-model="taskName"
                  limit-show
                  max-length="50"
                  class="biggerFont">
    </nut-textarea>
    <div style="margin-top: 12px">
      <CreateStartAndEndTime :all-day="allDay"
                             @timeEnd="v=>this.timeEnd=v" @timeStart="v=>this.timeStart=v"/>
    </div>
    <div class="divider"></div>
    <AllDaySwitch @allDayChange="onAllDayChange"/>
    <div style="margin-top: 12px">
      <ParticipantBarView @selectedUserId="onSelectedUser"
                          :userIds="userIds"
                          :content="userIds.length>0?`已选择${userIds.filter(v=>v<0).length}人`:null"/>
    </div>
    <div style="margin-top: 12px">
      <CatalogSelectorBar @confirmVersion="onConfirmVersion"/>
    </div>
    <div style="margin-top: 12px">
      <RemindTypeBarView  @dingRemind="onDingRemind" @smsRemind="onSmsRemind"/>
    </div>
    <AttachmentBarView style="margin-top: 12px"
                       v-if="attachments.length>0"
                       @uploadedFiles="onUploadedFiles"
                       :data="attachments" @remove="removeAttachment"/>
    <RepeatBarView style="margin-top: 12px" :data="repeat" v-if="repeat"/>
    <DescriptionBarView :data="description"
                        :key="description"
                        v-if="description" style="margin-top: 12px"/>
    <div style="margin-top: 12px">
      <TaskThreeButton
          :repeatField="repeat == null"
          :attachmentField="attachments.length < 1"
          :descriptionField="description == null"
          @repeat="onRepeat"
          @uploadedFiles="onUploadedFiles"
          @description="onDescription"/>
    </div>
    <nut-row class="bothCenter">
      <nut-button shape="rect brd4 mt32"
                  @click="onSubmit"
                  style="width: 90%;height: 40px;color: white;background: #1E427A;border: none">创建
      </nut-button>
    </nut-row>
  </div>
</template>

<script>
import CreateStartAndEndTime from "@/components/catalog/create/CreateStartAndEndTime";
import AllDaySwitch from "@/components/catalog/create/AllDaySwitch";
import ParticipantBarView from "@/components/catalog/create/ParticipantBarView";
import RemindTypeBarView from "@/components/catalog/create/RemindTypeBarView";
import TaskThreeButton from "@/components/catalog/create/TaskThreeButton";
import CatalogSelectorBar from "@/components/catalog/create/CatalogSelectorBar";
import AttachmentBarView from "@/components/catalog/create/AttachmentBarView";
import RepeatBarView from "@/components/catalog/create/RepeatBarView";
import DescriptionBarView from "@/components/catalog/create/DescriptionBarView";
import api from "@/api";

export default {
  name: "DataReportTaskCreateView",
  components: {
    DescriptionBarView,
    RepeatBarView,
    AttachmentBarView,
    CatalogSelectorBar,
    TaskThreeButton, RemindTypeBarView, ParticipantBarView, AllDaySwitch, CreateStartAndEndTime
  },
  data() {
    return {
      dingRemindType: 0,
      dingRemindTime: 0,
      smsRemindType: 0,
      smsRemindTime:0,
      attachments: [],
      taskName: null,
      remindType: null,
      allDay: false,
      timeEnd: 0,
      timeStart: 0,
      userIds: [],
      versionId: null,
      description: null,
      repeat: null,
    }
  },
  methods: {
    onDingRemind(type, t) {
      this.dingRemindType = type;
      this.dingRemindTime = t;
    },
    onSmsRemind(type, t) {
      this.smsRemindType = type;
      this.smsRemindTime = t;
    },
    onSubmit() {
      api.createTask({
        taskName: this.taskName,
        startTime: this.timeStart,
        endTime: this.timeEnd,
        description: this.description,
        versionId: this.versionId,
        remindType: this.remindType,
        taskType: 1,
        checkedUsers: this.userIds,
        allDay: this.allDay,
        attachments: this.attachments,
        repeat: this.repeat,
        dingRemindType: this.dingRemindType,
        dingRemindTime: this.dingRemindTime,
        smsRemindType: this.smsRemindType,
        smsRemindTime:this.smsRemindTime,
      }).then(ret => {
        this.$router.replace('/task/detail/' + ret.id)
      })
    },
    onRepeat(op) {
      console.log('onRepeat', op)
      this.repeat = op;
    },
    removeAttachment(pos) {
      console.log('remove', pos)
      this.attachments.splice(pos, 1)
    },
    onUploadedFiles(files) {
      for (let url of Object.keys(files)) {
        this.attachments.push({
          name: files[url],
          url: url,
        })
      }
    },
    onDescription(desc) {
      this.description = desc;
    },
    onConfirmVersion(versionId) {
      this.versionId = versionId;
    },
    onSelectedUser(userIds) {
      this.userIds = userIds;
    },
    onAllDayChange(v) {
      this.allDay = v;
    },
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.CurrentDayCreateViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F9FC;
  padding-bottom: 100px;
}
</style>