<template>
  <div class="MessageItemContainer"  @click="onMessageClick">
    <nut-row>
      <nut-col :span="4" class="leftCenter">
        <img :src="data.icon" class="icon">
      </nut-col>
      <nut-col :span="14" class="colFlex">
        <span class="smallFont" v-if="!readed">
          <nut-badge dot color="#FF0000">
          {{ data.title }}
          </nut-badge>
        </span>
        <span class="smallFont" v-else>
          {{ data.title }}
        </span>
        <nut-ellipsis class="miniFont" style="color:#999999;" :content="data.subTitle" :rows="2"/>
<!--        <span >{{ data.subTitle }}</span>-->
      </nut-col>
      <nut-col :span="6" class="rightCenter">
        <span class="miniFont" style="color:#B2B2B2;">{{ data.createdTime.substring(5, 16) }}</span>
      </nut-col>
    </nut-row>
    <div class="divider vmg8"></div>
  </div>
  <nut-dialog
      v-model:visible="dialogVisible"
      :title="data.dialogTitle"
      :ok-text="data.dialogOkText"
      cancel-text="取消"
      @ok="onOkBtnClick"
      :content="data.dialogContent">

  </nut-dialog>
</template>

<script>
import api from "@/api";

export default {
  name: "MessageItem",
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  methods: {
    onMessageClick() {
      api.markRead(this.data.id);
      this.readed = true;
      if (this.data.dialogTitle) {
        this.dialogVisible = true;
      }
    },
    onOkBtnClick() {
      if (this.data.redirectUrl) {
        this.$router.push(this.data.redirectUrl);
      }
    },
  },
  data() {
    return {
      readed: this.data.readed,
      dialogVisible: false
    }
  },
}
</script>

<style lang="scss" scoped>
.MessageItemContainer {
  display: flex;
  flex-direction: column;
  background-color: white;

  .icon {
    width: 46px;
    height: 46px;
  }
}
</style>