<template>
  <div class="RemindChannelSelectorContainer">
    <nut-row class="bothCenter">
      <nut-col :span="12" class="rightCenter">
        <nut-button :class="tab == 0?'active':'disActive'"
                    size="small"
                    @click="tab=0"
                    shape="rect" style="border-radius: 4px 0 0 4px">钉通知
        </nut-button>
      </nut-col>
      <nut-col :span="12" class="leftCenter">
        <nut-button :class="tab == 1?'active':'disActive'"
                    size="small"
                    @click="tab=1"
                    shape="rect" style="border-radius: 0 4px 4px 0">&nbsp;&nbsp;短信&nbsp;&nbsp;
        </nut-button>
      </nut-col>
    </nut-row>
    <div style="background-color: #F2F2F2;height: 10px" class="vmg16"></div>
    <template v-if="tab == 0">
      <template v-for="(t,ti) in remindTimes" :key="ti">
        <div class="divider" v-if="ti>0"></div>
        <nut-row className="bothCenter pd12" @click="confirmDing(t)">
          <nut-col :span="20" class="leftCenter">
            <span>{{ t.label }}</span>
          </nut-col>
          <nut-col :span="4" class="bothCenter">
            <div style="width: 24px;height: 24px">
              <img src="https://cdn.waityou.online/1b49790c-9ded-3ec9-1439-2ee7c1d15bdd.svg"
                   v-show="dingRemindType == t.value"
                   style="width: 24px;height: 24px"/>
            </div>
          </nut-col>
        </nut-row>
      </template>
      <template v-if="dingRemindType==4">
        <div class="divider"></div>
        <nut-row className="leftCenter pd12">
          <nut-col :span="8" class="leftCenter">
            <span>自定义时间</span>
          </nut-col>
          <nut-col :span="16" class="leftCenter" @click="dingRemindTimeVisible=true">
            <span v-if="dingRemindTimeStr">{{ dingRemindTimeStr }}</span>
            <span v-else>点击选择</span>
          </nut-col>
        </nut-row>
      </template>
    </template>
    <template v-if="tab == 1">
      <template v-for="(t,ti) in remindTimes" :key="'sms_'+ti">
        <div class="divider" v-if="ti>0"></div>
        <nut-row className="bothCenter pd12" @click="confirmSms(t)">
          <nut-col :span="20" class="leftCenter">
            <span>{{ t.label }}</span>
          </nut-col>
          <nut-col :span="4" class="bothCenter">
            <div style="width: 24px;height: 24px">
              <img src="https://cdn.waityou.online/1b49790c-9ded-3ec9-1439-2ee7c1d15bdd.svg"
                   v-if="smsRemindType == t.value"
                   style="width: 24px;height: 24px"/>
            </div>
          </nut-col>
        </nut-row>
      </template>
      <template v-if="smsRemindType==4">
        <div class="divider"></div>
        <nut-row className="leftCenter pd12">
          <nut-col :span="8" class="leftCenter">
            <span>自定义时间</span>
          </nut-col>
          <nut-col :span="16" class="leftCenter" @click="smsRemindTimeVisible=true">
            <span v-if="smsRemindTimeStr">{{ smsRemindTimeStr }}</span>
            <span v-else>点击选择</span>
          </nut-col>
        </nut-row>
      </template>
    </template>
  </div>
  <nut-datepicker
      v-model="dingRemindTime"
      title="自定义时间选择"
      type="datetime"
      :min-date="minDate"
      @confirm="confirmDingRemindTime"
      v-model:visible="dingRemindTimeVisible"
  ></nut-datepicker>
  <nut-datepicker
      v-model="smsRemindTime"
      title="自定义时间选择"
      type="datetime"
      :min-date="minDate"
      @confirm="confirmSmsRemindTime"
      v-model:visible="smsRemindTimeVisible"
  ></nut-datepicker>
</template>

<script>
import api from "@/api";

export default {
  name: "RemindChannelSelector",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
  data() {
    return {
      tab: 0,
      dingRemindType: 0,
      smsRemindType: 0,
      dingRemindTime: null,
      dingRemindTimeStr: null,
      smsRemindTime: null,
      smsRemindTimeStr: null,
      dingRemindTimeVisible: false,
      smsRemindTimeVisible: false,
      remindTimes: [],
      minDate: new Date(),
    }
  },
  methods: {
    confirmSms(t) {
      this.smsRemindType = t.value;
      this.$emit('smsRemind', t.value, this.smsRemindTime)
    },
    confirmDing(t) {
      this.dingRemindType = t.value;
      this.$emit('dingRemind', t.value, this.dingRemindTime)
    },
    confirmDingRemindTime(e) {
      this.dingRemindTimeStr = `${e.selectedValue[0]}-${e.selectedValue[1]}-${e.selectedValue[2]} ${e.selectedValue[3]}:${e.selectedValue[4]}:00`;
      this.$emit('dingRemind', this.dingRemindType, this.dingRemindTimeStr)
    },
    confirmSmsRemindTime(e) {
      this.smsRemindTimeStr = `${e.selectedValue[0]}-${e.selectedValue[1]}-${e.selectedValue[2]} ${e.selectedValue[3]}:${e.selectedValue[4]}:00`;
      this.$emit('smsRemind', this.smsRemindType, this.smsRemindTimeStr)
    },
    init() {
      api.getRemindTimes()
          .then(ret => {
            this.remindTimes = ret;
          })
      if(this.data){
        console.log('init',this.data)
        this.dingRemindType = this.data.dingRemindType;
        this.dingRemindTimeStr = this.data.dingRemindTime;
        this.dingRemindTime = this.data.dingRemindTime;

        this.smsRemindType = this.data.smsRemindType;
        this.smsRemindTimeStr = this.data.smsRemindTime;
        this.smsRemindTime = this.data.smsRemindTime;
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.RemindChannelSelectorContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px 0;

  .active {
    background-color: #1E427A;
    color: white;
    border: #1E427A 1px solid;
  }

  .disActive {
    background-color: white;
    color: #1E427A;
    border: #1E427A 1px solid;
  }
}
</style>