<template>
  <div class="CatalogStatisticCardContainer">
    <div class="wbg" style="padding: 10px">
      <span class="smallFont">{{ data.name }}</span>
      <div style="display: flex;align-items: flex-end;margin: 8px 0">
        <span style="font-size: 28px">{{ data.number }}</span>
        <span class="miniFont" style="color:#999999;padding-bottom: 5px;padding-left: 5px">{{ data.unit }}</span>
      </div>
      <span class="miniFont" style="color:#999999;">{{ data.fileSize }}</span>
      <nut-row class="leftCenter">
        <span class="miniFont" style="color:#999999;">昨日新增</span>
        <nut-icon name="triangle-up" color="red" size="10"></nut-icon>
<!--        <nut-icon name="triangle-down" color="green" size="10"></nut-icon>-->
        <span class="miniFont" style="color:#999999;">{{ data.yesterday }}</span>
      </nut-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "CatalogStatisticCard",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.CatalogStatisticCardContainer {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding: 10px;
}
</style>